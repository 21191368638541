import React from "react"
import classes from '../Calc.module.css'
import Krug from "../Krug";

const PeriodEvent = ({periods, current, emotions, currentEmotion, role, lang, birthday, nextPeriod }) => {

    let eventPeriod
    let emotionPeriod
    let eventDuration = []
    let emotionDuration = []
    let eventGain = []
    let emotionGain = []

    if (periods[current] !== undefined){
        for (let item=0; item < periods[current].length; item++){
            if (periods[current][item].name === 'period'){
                eventPeriod = periods[current][item].value;
            } else if (periods[current][item].name === 'duration'){
                eventDuration = (periods[current][item].value.split('-'));
            } else if (periods[current][item].name === 'eventCircle_gain'){
                eventGain = periods[current][item].value
            }
        }
    }

    if (emotions[currentEmotion] !== undefined){
        for (let item=0; item < emotions[currentEmotion].length; item++){
            if (emotions[currentEmotion][item].name === 'period'){
                emotionPeriod = (emotions[currentEmotion][item].value);
            } else if (emotions[currentEmotion][item].name === 'duration'){
                emotionDuration = emotions[currentEmotion][item].value.split('-')
            } else if (emotions[currentEmotion][item].name === 'emotionCircle_gain'){
                emotionGain = emotions[currentEmotion][item].value
            }
        }
    }

    return (
        <div className={classes.forecast__container}>
            <h2>ПЕРИОД</h2>
            <div className={classes.event}>
                <div>
                    {periods[current] !== undefined &&
                        <div>
                            <h3>СОБЫТИЯ</h3>
                            <p>с 21.09.{eventDuration[0]} по 21.09.{eventDuration[1]}</p>
                            <Krug data={periods[current]} role={role} lang={lang} mission={eventPeriod} misssion_e='1' page='emotions' gain={eventGain}  className='krug__period' popUpLink='Period' /> 
                        </div>
                    }
                </div>

                <div>
                    {emotions[currentEmotion] !== undefined &&
                        <div>
                            <h3>ЭМОЦИИ</h3>
                            {nextPeriod ? <p>с {birthday}.{parseInt(emotionDuration[0])+81} по {birthday}.{parseInt(emotionDuration[1])+81}</p> : <p>с {birthday}.{emotionDuration[0]} по {birthday}.{emotionDuration[1]}</p>}
                            <Krug data={emotions[currentEmotion]} role={role} lang={lang} mission={emotionPeriod} misssion_e='1' page='emotions' gain={emotionGain}   className='krug__period' popUpLink='Period'  /> 
                        </div>
                    }
                </div>
            </div>

        </div>
    )
};

export default PeriodEvent;
