import {useState} from 'react';
import axios from '../../api/axios';

const Restore = () => {

	const RESTORE_URL = '/profile/find_user';
	const [email, setEmail] = useState('');
    
    const [visible, setVisible] = useState(true)

    const handleSubmit = async (e) => {
        e.preventDefault();
		setVisible(false)
        try {
			const response = await axios.post(
				RESTORE_URL,
				JSON.stringify({ 
					'email': email
				}),
				{ headers: { 'Content-Type': 'application/json' }}
			);
			if (response.status === 200){
				alert('Инструкция по востановлению пароля отправлена на почту')
				window.location.href = '/'
			} else {
				setVisible(true) 
			}
        } catch (error){
            if (error.response?.status !== 200){
                alert(error.response?.data.message)
				setVisible(true)
            } else if (error.response?.status === 404){
				alert('Пользователь не найден')
			}
        }
    }

    return (
        <div className='form__cotainer'>
            <section>

					<h1>Воcстановить пароль</h1>
					<form onSubmit={handleSubmit}>
                    	<h4 className={!visible ? 'restoreInfo' : 'none'}>Обработка запроса...</h4>

						<label htmlFor="email" className={visible ? '' : 'none'}>Почта:</label >
						<input
							type="text"
							id="email"
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							placeholder='Почта'
							className={visible ? '' : 'none'}
						/>

						<button className={visible ? 'customBtn' : 'none'}>Сбросить пароль</button>
						
					</form>




					<div className='line__container'>
						<span className="line backBtn">
							<a href="/page/register"> Зарегистрироваться</a>
						</span>

						<span className="line backBtn">
							<a href="/page/login"> Войти в профиль</a>
						</span>
					</div>
					
				</section>   

                <span className="line backBtn">
					<a href="/"> Назад </a>
				</span>
        </div>
    )
};

export default Restore;
