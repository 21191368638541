import React, {useState} from "react"
import axios from "../../../api/axios";
import classes from '../../pages/styles/match.module.css'

import Input from "../../UI/input/Input";
import Dropdown from "../../UI/dropdown/Dropdown";
import Button from "../../UI/button/Button";

const Name = () => {

    const MATCHNAME_URL = '/calculation/selection';

    const token = localStorage.getItem('token')
    const [isDisabled, setIsDisabled] = useState(true)

    const [list, setList] = useState([])
    const [mission, setMission] = useState('')
    const [familyname, setFamilyname] = useState('')

    const [placeholder, setPlaceholder] = useState('')
    const [data, setData] = useState('')
    const [userSearch, setUserSearch] = useState('')
    const [gender, setGender] = useState('')

    const searchOption = [
        { title: 'Выберите один вариант', value: 'Введите данные', name: 'Введите данные'},
        { title: 'Подобрать имя', value: 'name', name: 'Введите отчество'},
        { title: 'Подобрать отчество', value: 'lastname', name: 'Введите имя' }
    ];

    const chooseSearch = (option, item, name) => {
        setUserSearch(option);
        setPlaceholder(name)
        setIsDisabled(false)
        if (item === ''){
            setIsDisabled(true)
        }
    };

    const genderDrop = [
        { title: 'Выберите один вариант'},
        { title: 'муж', value: 'male'},
        { title: 'жен', value: 'female' }
    ];

    const chooseGender = (option) => {
        setGender(option);
    };

    const searchName = async () => {
        if (mission !== '' && gender !== '' && familyname !== '' && userSearch !== '' && data !== '' && data !== '' ){
            if (mission < 9){
                try {
                    const response = await axios.post(
                        MATCHNAME_URL,
                        JSON.stringify({ 
                            'mission': mission,
                            'gender': gender,
                            'familyname': familyname,
                            'option' : userSearch,
                            'data' : data,
                            'lang': 'ru'
                        }),
                        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`}}
                    );
                    setList(response.data);
                } catch (err) {
                    alert(err.response.data.message)
                }
            } else {
                alert('Миссия не может быть больше 9')
            }
        } else {
            alert('Все поля должны быть заполнены')
        }

	};

    const cleanData = () => {
        setMission(''); setFamilyname('')
        setData(''); setList([])
    }

    return (
        <>
            <div className={classes.calculateName} >
                <h3 style={{ marginBottom: '10px'}} >* Подбор имени - важная часть в жизни человека. Если у родителей карты с разными основными числами, то его лучше предоставить специалисту</h3>
                <div className={classes.page}>
                    <div className={classes.match}>
                        <div className={classes.matchItem}>
                            <p>Миссия:</p>
                            <Input 
                                type='number'
                                value={mission}
                                onChange={(e) => setMission(e.target.value)}
                                required={true}
                                placeholder='Миссия'
                            ></Input>
                        </div>

                        <div className={classes.matchItem}>
                            <p>Фамилия:</p>
                            <Input 
                                type='text'
                                value={familyname}
                                onChange={(e) => setFamilyname(e.target.value)}
                                required={true}
                                placeholder='Фамилия'
                            ></Input>
                        </div>

                        <div className={classes.matchItem}>
                            <p>Подобрать имя или отчество: </p>
                            <Dropdown handleOptionChange={chooseSearch} option={searchOption} />
                        </div>
                        <div className={classes.matchItem}>
                            <p>{placeholder}</p>
                            <Input 
                                type='text'
                                value={data}
                                onChange={(e) => setData(e.target.value)}
                                required={true}
                                placeholder={placeholder}
                                disabled={isDisabled}
                            ></Input>
                        </div>
                        <div className={classes.matchItem}>
                            <p>Выберите пол</p>
                            <Dropdown handleOptionChange={chooseGender} option={genderDrop}/>
                        </div>

                        <Button 
                            className='customBtn'
                            onClick={searchName}
                        >
                            <span>Подобрать</span>
                        </Button>

                        <Button 
                            className='customBtn'
                            onClick={cleanData}
                        >
                            <span>Сбросить</span>
                        </Button>

                    </div>

                    <div className={classes.nameList}>
                        {list.length !== 0 ?
                            <div>
                                <h4 style={{ marginBottom: '10px'}} >Список рекомендуемых имён</h4>
                                <div className={classes.result}>
                                    {list.map((item, index) => 
                                        <p key={index}  >{item.value}</p>
                                    )}
                                </div>
                            </div>
                        : <h4><i>Здесь будут результаты поиска</i></h4>
                        }    
                    </div>
                                
                </div>
            </div>

        </>
    )
};

export default Name;
