import React, { useState, useEffect } from "react";
import { mini_circle_list__years } from "../../../../api/krugData";
import { Link } from "react-router-dom";
import classes from '../Calc.module.css';
import axios from "../../../../api/axios";

import GainItem from "./GainItem";
import FullGain from "./GainFull";
import ColumnYears from "./YearsColumn";

const YearItem = ({ item, number, person, changeYear }) => {
    
    const DESCRIPTION_URL = '/calculation/description';

    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [hoverDesc, setHoverDesc] = useState('');
    const [hoverLink, setHoverLink] = useState('')

    const handleClick = async ( item, event) => {
        console.log(item);
        const rect = event.target.getBoundingClientRect();
        const x = rect.left + window.scrollX - 20;
        const y = rect.top + window.scrollY +50;
        try {
            const response = await axios.post(
                DESCRIPTION_URL,
                JSON.stringify({
                    'table': 'Year',
                    'name': item.name,
                    'value': item.value
                }), { headers: { 'Content-Type': 'application/json' } }
            );
            setHoverDesc(response.data.description);
            setHoverLink(response.data.name)
            setPopupPosition({ x, y });
        } catch (err) {
            console.log(err);
        }
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popup = document.querySelector('.popupTool');
            if (popup && !popup.contains(event.target)) {
                setHoverDesc('')
                setHoverLink('')
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <div className={classes.period__container}>
            <button onClick={() => changeYear(number, person)} className={classes.changeBtn}>Выбрать</button>
            <div className={classes.yearItem}>
                {item !== undefined &&
                    item.map((year, index) => {
                        if (year.name === "column_years_left") {
                            return (
                                <div className={classes.columnYears__left} key={index}>
                                    {year.value.map((y, index) => (
                                        <ColumnYears date={y} key={index} />
                                    ))}
                                </div>
                            );
                        } else if (year.name === "column_years_right") {
                            return (
                                <div className={classes.columnYears__right} key={index}>
                                    {year.value.map((y, index) => (
                                        <ColumnYears date={y} key={index} />
                                    ))}
                                </div>
                            );
                        } else if (mini_circle_list__years.includes(year.name)){
                            return (
                                <div key={index}>
                                    <span className={`year__${year.name}`} onClick={(event) => handleClick(year, event)} >{year.value}</span>
                                </div>
                            );
                        } else if (year.name === 'circle_year'){
                            return (
                                <div key={index}>
                                    <span className={`year__${year.name}`}>{year.value}</span>
                                </div>
                            )
                        }
                    })}
            </div>

            <div className={classes.container}>
                {item !== undefined &&
                    item.map((year, index) => {
                        if (year.name === "miniCircle_main_gain") {
                            return (
                                <div className={classes.container} key={index}>
                                    {year.value.map((data, index) => (
                                        <FullGain item={data} key={index} />
                                    ))}
                                </div>
                            );
                        }
                    })
                }
            </div>

            <hr />

            <div className={classes.container}>
                {item !== undefined &&
                    item.map((year, index) => {
                        if (year.name === "miniCircle_gain") {
                            return (
                                <div className={classes.container} key={index}>
                                    {year.value.map((data, index) => (
                                        <GainItem item={data} key={index} />
                                    ))}
                                </div>
                            );
                        }
                    })}
            </div>

            {hoverDesc && (
                    <div className="popupTool" style={{ top: popupPosition.y, left: popupPosition.x }}>
                        {hoverDesc}
                        <Link
                            to={{
                                pathname: '/page/book',
                                search: `data=${hoverLink}`,
                            }}
                            target="_blank"
                        >
                            <p className='popLink'>Подробнее</p>
                        </Link>
                    </div>
                )}
        </div>
    );
};

export default YearItem;
