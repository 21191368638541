import React, { useState, useEffect } from "react";
import { notAuth, excludeList, mainKrug, fullKrug, fullKrug_e, krug_pro, grafic, eventCircle__period, eventCircle__year } from "../../../api/krugData";
import axios from "../../../api/axios";
import classes from './Calc.module.css';
import { Link } from "react-router-dom";

import GainItem from "./forecast/GainItem";

const Krug = ({ data, role, gain, gain_e, mission, misssion_e, className, lang, container, popUpLink, page }) => {
    
    const DESCRIPTION_URL = '/calculation/description';

    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [hoverDesc, setHoverDesc] = useState('');
    const [hoverLink, setHoverLink] = useState('')

    const handleClick = async ( item, event) => {
        const rect = event.target.getBoundingClientRect();
        const x = rect.left + window.scrollX - 20;
        const y = rect.top + window.scrollY +90;
        try {
            const response = await axios.post(
                DESCRIPTION_URL,
                JSON.stringify({
                    'table': popUpLink,
                    'name': item.name,
                    'value': item.value
                }), { headers: { 'Content-Type': 'application/json' } }
            );
            setHoverDesc(response.data.description);
            setHoverLink(response.data.name)
            setPopupPosition({ x, y });
        } catch (err) {
        }
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popup = document.querySelector('.popupTool');
            if (popup && !popup.contains(event.target)) {
                setHoverDesc('')
                setHoverLink('')
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className={container}>
            <div className='calcResult'>
                <div className={className}>

                    {data.map((item) => {
                        if (excludeList.includes(item.name)){
                            return 
                        }
                    })}

                    {role === 'notAuth' &&
                        data.map((item, index) => {
                            if (notAuth.includes(item.name)){
                                return <div key={index} className={`${item.name}_simple`}>...</div>;
                            } else if (mainKrug.includes(item.name)) {
                                return <div key={index} className={`${item.name}_simple`}>{item.value}</div>
                            }
                        })
                    }

                    {role === 'AuthUser' &&
                        data.map((item, index) => {
                            if (fullKrug.includes(item.name) || eventCircle__year.includes(item.name) || eventCircle__period.includes(item.name)) {
                                return <div key={index}
                                            onClick={(event) => handleClick(item, event)}
                                            className={`${item.name}_simple`}
                                        >
                                            <span>{item.value}</span>
                                        </div>
                            } 
                        })
                    }

                    {role === 'student' &&
                        data.map((item, index) => {
                            if (lang === 'ru_e') {
                                if (krug_pro.includes(item.name)){
                                    return <div className={item.name} key={index}>
                                                <span onClick={(event) => handleClick(item, event)} >/{item.value}</span>
                                            </div>
                                } else if (fullKrug.includes(item.name)){
                                    return <div className={item.name} key={index}>
                                                <span onClick={(event) => handleClick(item, event)}>{item.value}</span>
                                            </div>
                                } else if (eventCircle__period.includes(item.name) || eventCircle__year.includes(item.name)){
                                    return <div key={index} className={`${item.name}_simple`}>
                                                <span onClick={(event) => handleClick(item, event)}>{item.value}</span>
                                            </div>
                                }
                            } else if (lang === 'e'){
                                if (fullKrug_e.includes(item.name)) {
                                    return <div key={index} className={`${item.name.slice(0, -2)}_simple`}>
                                                <span onClick={(event) => handleClick(item, event)}>{item.value}</span>
                                            </div>
                                } else if (eventCircle__period.includes(item.name) || eventCircle__year.includes(item.name)){
                                    return <div key={index}
                                                onClick={(event) => handleClick(item, event)}
                                                className={`${item.name}_simple`}
                                            >
                                                <span  onClick={(event) => handleClick(item, event)} >{item.value}</span>
                                            </div>
                                }
                            } else {
                                if (fullKrug.includes(item.name) || eventCircle__period.includes(item.name) || eventCircle__year.includes(item.name)) {
                                    return <div key={index} className={`${item.name}_simple`}>
                                                <span onClick={(event) => handleClick(item, event)}>{item.value}</span>
                                            </div>
                                } 
                            }
                        })
                    }
                </div>


                {hoverDesc && (
                    <div className="popupTool" style={{ top: popupPosition.y, left: popupPosition.x }}>
                        {hoverDesc}
                        <Link
                            to={{
                                pathname: '/page/book',
                                search: `data=${hoverLink}`,
                            }}
                            target="_blank"
                        >
                            <p className='popLink'>Подробнее</p>
                        </Link>
                    </div>
                )}


                <div className={classes.grafic__box}>
                    {role !== 'notAuth' && data.length !== 0 && mission !== 0 && mission !== undefined && misssion_e !== undefined && misssion_e !== 0 &&
                        <div>
                            {lang === 'ru_e' && page !== 'emotions' ? (
                                <div className={classes.grafic_e}>
                                    <div className={classes.grafic}>
                                        <img src={mission && grafic[mission].grafic} />
                                        <p>{mission && grafic[mission].description}</p> 
                                        <h3>УСИЛЕНИЯ:</h3>
                                        <div className={classes.gain__container}>
                                            {gain !== undefined && gain.length !== 0 ? 
                                            gain.map((item, index) => (
                                                <GainItem item={item} key={index} />
                                            )) : 
                                            <p><i>Усилений нет</i></p>
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.grafic}>
                                        <img src={grafic[misssion_e].grafic} />
                                        <p>{grafic[misssion_e].description}</p>  
                                        <h3>УСИЛЕНИЯ:</h3>
                                        <div className={classes.gain__container}>
                                            {gain_e !== undefined && gain_e.length !== 0 ? 
                                            gain_e.map((item, index) => (
                                                <GainItem item={item} key={index} />
                                            )) : 
                                            <p><i>Усилений нет</i></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.grafic}>
                                    <img src={mission && grafic[mission].grafic} />
                                    <p>{mission && grafic[mission].description}</p> 
                                    <h3>УСИЛЕНИЯ:</h3>
                                    <div className={classes.gain__container}>
                                    {gain !== undefined && gain.length !== 0 ? 
                                        gain.map((item, index) => (
                                        <GainItem item={item} key={index} />
                                        )) : 
                                        <p><i>Усилений нет</i></p>
                                    }
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>

                </div>
        </div>
    )
};

export default Krug;
