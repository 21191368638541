import React, {useState, useEffect} from "react"
import axios from "../../../api/axios";
import classes from './plot.module.css'
import { StudentOption, option } from "../../../api/krugData";
import Input from "../../UI/input/Input";
import Krug from "../Calculation/Krug";
import Button from "../../UI/button/Button";
import Dropdown from "../../UI/dropdown/Dropdown";

const PlotCalc = ({role}) => {

    const CALCULATEPLOT_URL = '/calculation/instructions'
    const token = localStorage.getItem('token')
    let [lang, setLang] = useState(localStorage.getItem('lang') || 'ru' )
    const [dropdownOption, setDropDown] = useState(option)
    const [isLoading, setIsLoading] = useState(false);

    const [mainData, setMainData] = useState([])

    const [username, setUsername] = useState(localStorage.getItem('parentName') || '')
    const [lastname, setLastname] = useState(localStorage.getItem('parentlastname') || '')
    const [familyname, setFamilyname] = useState(localStorage.getItem('parentfamilyname') || '')
    const [birthday, setBirthday] = useState(localStorage.getItem('parentbirthday') || '')
    const [month, setMounth] = useState(localStorage.getItem('parentmonth') || '')
    const [year, setYear] = useState(localStorage.getItem('parentyear') || '')
    const [userData, setUserData] = useState([])

    const [childName, setChildname] = useState(localStorage.getItem('childName') || '')
    const [childLastname, setChildLastname] = useState(localStorage.getItem('childlastname') || '')
    const [childFamilyname, setChildFamilyname] = useState(localStorage.getItem('childfamilyname') || '')
    const [childBirthday, setChildBirthday] = useState(localStorage.getItem('childbirthday') || '')
    const [childMonth, setChildMounth] = useState(localStorage.getItem('childmonth') || '')
    const [childYear, setChildYear] = useState(localStorage.getItem('childyear') || '')
    const [childData, setChildData] = useState([])

    useEffect(() => {
        if (role === 'student'){
            setDropDown(StudentOption)
        } else {setDropDown(option)}
    })

        const Calculate = async () =>{
            setIsLoading(true)
        if (username !=='' && lastname !== '' && familyname !== '' && birthday!== '' && month !== '' && year !== '' &&
            childName !=='' && childLastname !== '' && childFamilyname !== '' && childBirthday !== '' && childMonth !== '' && childYear !== ''){
            let head
            try {
                if (lang === ''){lang = localStorage.getItem('lang') || 'ru' }
                if (role === 'notAuth'){head = { 'Content-Type': 'application/json'}} else {
                    head = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`}}
			const response = await axios.post(
				CALCULATEPLOT_URL,
				JSON.stringify({
                    'name': username, 'childName' : childName,
                    'familyname': familyname, 'childFamilyname': childFamilyname,
                    'lastname': lastname, 'childLastname' : childLastname,
                    'birthday': birthday, 'childBirthday': childBirthday,
                    'month': month, 'childMonth': childMonth,
                    'year': year, 'childYear': childYear,
                    'lang': lang
				}),{headers: head} 
			);

                localStorage.setItem('parentName', username)
                localStorage.setItem('parentfamilyname', familyname)
                localStorage.setItem('parentlastname', lastname)
                localStorage.setItem('parentbirthday', birthday)
                localStorage.setItem('parentmonth', month)
                localStorage.setItem('parentyear', year)

                localStorage.setItem('childName', childName)
                localStorage.setItem('childfamilyname', childFamilyname)
                localStorage.setItem('childlastname', childLastname)
                localStorage.setItem('childbirthday', childBirthday)
                localStorage.setItem('childmonth', childMonth)
                localStorage.setItem('childyear', childYear)

                setUserData(response.data.parent_circle)
                setChildData(response.data.child_circle)
                setMainData(response.data.main_circle)
                setIsLoading(false)
		} catch (err) {
            alert(err.response.data.message)
            setIsLoading(false)
            } 
        } else {
            alert('Все поля должны быть заполнены')
            setIsLoading(false)
        }
    }

    function reset(){
        setUsername(''); setChildname(''); setLastname('');
        setChildLastname(''); setFamilyname(''); setChildFamilyname('');
        setBirthday(''); setChildBirthday(''); setMounth('');
        setChildMounth(''); setYear(''); setChildYear('');
        setUserData([]); setChildData([]); setMainData([])
    }

    const handleOptionChange = (option) => {
        setLang(option)
        setUserData([])
        setChildData([])
        setMainData([])
    }

    return (
        <>
            {isLoading &&
                <div className="spinner_box"><div className="spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div></div>
            }

        <div className={classes.plotpage}>
            <div className="left">
                <h2>Данные родителя</h2>
                    <form className={classes.calcInput}>
                            <Input 
                                type='text'
                                id='familyname'
                                name='familyname'
                                value={familyname}
                                onChange={(e) => setFamilyname(e.target.value)}
                                placeholder='Фамилия'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='text'
                                id='username'
                                name='username'
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder='Имя'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='text'
                                id='lastname'
                                name='lastname'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder='Отчество'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                    </form>

                    <form className={classes.calcInput}>
                            <Input 
                                type='number'
                                id='birthday'
                                name='birthday'
                                value={birthday}
                                onChange={(e) => setBirthday(e.target.value)}
                                placeholder='день рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='number'
                                id='month'
                                name='month'
                                value={month}
                                onChange={(e) => setMounth(e.target.value)}
                                placeholder='месяц рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='number'
                                id='year'
                                name='year'
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                                placeholder='год рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                    </form>

                    <Krug data={userData} role={role} lang={lang} className='krug__middle' popUpLink='Circle'/>
            </div>

            <div className={classes.middle}>
                <h1>Родительские предписания</h1>

                <div className={classes.button__block}>
                    <Dropdown option={dropdownOption} handleOptionChange={handleOptionChange} />

                    <Button className={classes.calc} onClick={Calculate} disabled={isLoading}>
                        <span>Посчитать</span>
                    </Button>

                    <Button className={classes.clean}  onClick={reset}>
                        <span>Сбросить</span>
                    </Button>
                </div>


                {role === 'student' && <Krug data={mainData} role={role} className='krug__middle' lang={lang} popUpLink='Circle'  />}
            </div>

            <div className="right">
                <h2>Данные ребёнка</h2>
                    <form className={classes.calcInput}>
                            <Input 
                                type='text'
                                id='familyname'
                                name='familyname'
                                value={childFamilyname}
                                onChange={(e) => setChildFamilyname(e.target.value)}
                                placeholder='Фамилия'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='text'
                                id='username'
                                name='username'
                                value={childName}
                                onChange={(e) => setChildname(e.target.value)}
                                placeholder='Имя'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='text'
                                id='lastname'
                                name='lastname'
                                value={childLastname}
                                onChange={(e) => setChildLastname(e.target.value)}
                                placeholder='Отчество'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                    </form>

                    <form className={classes.calcInput}>
                            <Input 
                                type='number'
                                id='birthday'
                                name='birthday'
                                value={childBirthday}
                                onChange={(e) => setChildBirthday(e.target.value)}
                                placeholder='день рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='number'
                                id='month'
                                name='month'
                                value={childMonth}
                                onChange={(e) => setChildMounth(e.target.value)}
                                placeholder='месяц рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                            <Input 
                                type='number'
                                id='year'
                                name='year'
                                value={childYear}
                                onChange={(e) => setChildYear(e.target.value)}
                                placeholder='год рождения'
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter'){
                                        Calculate()
                                    }
                                }}
                            />
                    </form>

                    <Krug data={childData} role={role} lang={lang} className='krug__middle' popUpLink='Circle' />
            </div>
        </div>
    </>
    )
};

export default PlotCalc;
