import React, { useState, useEffect, useRef } from "react";
import classes from '../Calc.module.css';
import HoverItem from "../../../UI/hoverItem/HoverItem";

const FullGain = ({ item }) => {

    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    const openPopUp = () => {
        setIsVisible((prevVisible) => !prevVisible);
    };

    useEffect(() => {
        const outsideClick = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setIsVisible(false);
            }
        };
        document.addEventListener("click", outsideClick);
        return () => {
            document.removeEventListener("click", outsideClick);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            style={{ backgroundColor: item.color }}
            className={classes.fullgain}
            onClick={openPopUp}
        >
            <h5>{item.title}</h5>
            <p className={classes.gainText}>{item.gain}</p>
                {item.description !== null && (
                    <div className={classes.box}>
                        <HoverItem
                            visible={isVisible}
                            data={item.description}
                            link={item.title}
                        />
                    </div>
                )}
        </div>
    );
};

export default FullGain;
