import React from "react"
import classes from '../match/baseMatch.module.css'

const BaseMatch = ({base, gain, number}) => {
    return (
        <div className={classes.extermalMatch}>
            <h3>Основная внешняя совместимость</h3>
            {base.lenght !== 0 ?
                base.map((base, index) => {
                    return <p key={index}>{(base).toUpperCase()}</p>
                })
                : <p>Совместимостей нет</p>
            }
            <h3>СХОЖЕСТЬ</h3>
            <h3>Одинаковые УСИЛЕНИЯ</h3>
            {gain.lenght !== 0 ?
                gain.map((gain, index) => {
                return <p key={index}>{(gain).toUpperCase()}</p>
                })
                : <p>Совместимостей нет</p>
            }

            <h3>Одинаковые ЧИСЛА</h3>
            {number.lenght !== 0 ? 
                number.map((number, index) => {
                    return <p key={index}>{(number).toUpperCase()}</p>
                })
                : <p>Совместимостей нет</p>
            }
        </div>
    )
};

export default BaseMatch;
