import React, {useState} from "react"
import classes from './Calc.module.css'

const CalcInfo = () => {
    const [info, setInfo] = useState(false)

    return (
        <div className={classes.info}>
            <h5 onMouseOver={() => setInfo(true)} onMouseOut={() => setInfo(false)} style={{cursor: 'pointer', textDecoration: 'underline'}} >Как правильно ввести данные?</h5>
            {(info) &&
            <div>
                <p>- Обязательно указывайте ФИО, которые были даны при рождении;</p>
                <p>- Е или Ё имеет значение;</p>
                <p>- Если нет отчества, разделите фамилию на две части и впишите одну часть в поле “Фамилия”, вторую часть в поле “Отчество”;</p>
                <p>- Если фамилия двойная, то пишите её слитно (без пробелов и тире);</p>
                <p>- Месяц пишите числом, а не словом;</p>
            </div>}
        </div>
    )
};

export default CalcInfo;
