import React, { useState, useEffect, useRef } from "react";
import classes from '../Calculation/Calc.module.css'

import left from '../../pages/img/left.png'
import right from '../../pages/img/right.png'
import HoverItem from "../../UI/hoverItem/HoverItem";

const MatchCard = ({card}) => {

    const [isVisible, setIsVisible] = useState(false);
    const containerRef = useRef(null);

    const openPopUp = () => {
        setIsVisible((prevVisible) => !prevVisible);
    };

    useEffect(() => {
        const outsideClick = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setIsVisible(false);
            }
        };
        document.addEventListener("click", outsideClick);
        return () => {
            document.removeEventListener("click", outsideClick);
        };
    }, []);


    return (
        <div className={classes.card}>
            <div>
                <p>{card.person_1}</p>
                <p className={`${card.match[0].name}_color`}>
                    {(card.match[0].value).toUpperCase()}
                </p>
            </div>


            <div ref={containerRef} onClick={openPopUp} className={classes.cardIMG}> 
                <img src={`/img/png/gain/${card.image_path}`} alt="gain" />
                <h4 style={{color: card.color, cursor: 'pointer'}}>{(card.title).toUpperCase()}</h4>
                <div className={classes.box}>
                    <HoverItem  visible={isVisible} data={card.description} link={card.title}/>
                </div>

                <div className={classes.card__center}>
                    {card.influencer === card.person_1 ?
                        <div style={{display: 'flex'}}>
                            <img src={left} alt="arrow" width='15px' />
                            <p>{card.feature} {card.influencer}</p>
                        </div>
                        :
                        <div style={{display: 'flex'}}>
                            <p>{card.feature} {card.influencer}</p>
                            <img src={right} alt="arrow" width='15px' />
                        </div>
                    }
                </div>
            </div>

            <div>
                <p>{card.person_2}</p>
                <p className={`${card.match[1].name}_color`}>
                    {(card.match[1].value).toUpperCase()}
                </p>
            </div>
        </div>
    )
};

export default MatchCard;
