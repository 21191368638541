
import React from "react";
import './Button.css'

const Button = React.forwardRef(({ className, children, onClick, id, disabled, onKeyDown }, ref) => {
    return (
        <button ref={ref} className={className} onClick={onClick} id={id} disabled={disabled} onKeyDown={onKeyDown}>
            {children}
        </button>
    );
});

export default Button;
