import React, { useState, useEffect } from "react";
import axios from '../../api/axios';
import classes from '../blocks/rightPage/Video.module.css';

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Input from '../UI/input/Input'

import VideoBlock from "../blocks/rightPage/VideoBlock";
import InfoHead from "../UI/infoHead/infoHead";

const Video = () => {

    const VIDEO_URL = '/Video/category'

    const [videoCategory, setVideoCategory] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});
    const [loaded, setLoaded] = useState(true)
    const [error, setError] = useState(false)

    const [video, setVideo] = useState([])
    const [emptyVideo, setEmptyVideo] = useState(false)

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VIDEO_URL);
                setVideoCategory(response.data);

                const subcategoriesMap = {};
                for (const category of response.data) {
                    const subcategoriesResponse = await axios.get(`/Video/${category.id}/subcategory`);
                    subcategoriesResponse.data.sort((a, b) => a.title.localeCompare(b.title));
                    subcategoriesMap[category.title] = subcategoriesResponse.data;
                }
                setSubcategories(subcategoriesMap);

                setExpandedCategories({
                    [response.data[0].title]: true,
                    ...response.data.slice(1).reduce((acc, category) => {
                        acc[category.title] = false;
                        return acc;
                    }, {}),
                });
                setLoaded(true)
            } catch (error) {
                if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }
        };
        fetchData();
    }, []);

    const toggleCategory = (categoryTitle) => {
        setExpandedCategories((prevExpandedCategories) => ({
            ...prevExpandedCategories,
            [categoryTitle]: !prevExpandedCategories[categoryTitle],
        }));
    };

    const openDetails = async (item) => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/Video/${item}/video`);
                setVideo(response.data)
                setEmptyVideo(false)
            } catch (error) {
                if (error.response?.status === 404 ){
                    setEmptyVideo(true)
                    setVideo([])
                }
            }
        };
        fetchData();
    }

    const categoryMatches = (category) => {
        return category.toLowerCase().includes(searchInput.toLowerCase());
    };

    useEffect(() => {
        const filteredCategories = videoCategory.filter(category =>
            category.title.toLowerCase().includes(searchInput.toLowerCase()) ||
            (subcategories[category.title] && subcategories[category.title].some(subcategory =>
                subcategory.title.toLowerCase().includes(searchInput.toLowerCase())
            ))
        );
        const updatedExpandedCategories = {};

        if (filteredCategories.length > 0) {
            updatedExpandedCategories[filteredCategories[0].title] = true;
        }

        setFilteredCategories(filteredCategories);
        setExpandedCategories(updatedExpandedCategories);
    }, [searchInput, videoCategory, subcategories]);


    return (
        <>
            <Header/>
            {loaded &&
                <div className="contentSimple">
                    <InfoHead content='ВИДЕО' />
                    <div className="book">
                        <div className="leftPage">
                            {videoCategory.length === 0 ? 
                                <p><i>Здесь пока ничего нет</i></p> :
                                <div className="colums">
                                    <Input 
                                        type='search'
                                        placeholder='Поиск...'
                                        className={classes.search}
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    {!filteredCategories.length ? (<p className={classes.CategoryItem}><i>Ничего не найдено</i></p>) 
                                    : (filteredCategories.map((category, index) => (
                                                <div key={index}>
                                                    <div className={classes.CategoryItem} onClick={() => toggleCategory(category.title)}>
                                                        <h3>{category.title} </h3>
                                                    {expandedCategories[category.title] ? <span>свернуть</span> : <span>посмотреть</span> }
                                                    </div>
                                                    <hr />

                                                    {expandedCategories[category.title] && subcategories[category.title] && (
                                                        <ul>
                                                            {subcategories[category.title]
                                                            .filter(subcategory => categoryMatches(subcategory.title))
                                                            .map((subcategory, index) => (
                                                                <li key={index}
                                                                    onClick={() => openDetails(subcategory.id)}
                                                                    className={`${searchInput && categoryMatches(subcategory.title) ? 'highlight' : ''}`}
                                                                >{subcategory.title}</li>
                                                            ))}
                                                            {subcategories[category.title].length === 0 && <span><i>Подкатегорий пока нет</i></span> }
                                                        </ul>
                                                    )}
                                                </div>
                                            )))
                                    }
                                </div>
                            } 
                        </div>

                        <div className="rightPage">
                            {video.length !== 0 ?
                                video.map((video, index) =>
                                    <VideoBlock video={video} key={index} />
                                    )
                                : <p></p>
                            }
                            {emptyVideo && <p>Данных пока нет</p>}
                        </div>
                    </div>
                </div>
            }

            {error &&
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />
        </>
    )
};

export default Video;
