import React from 'react';
import CustomLink from '../../UI/customLink/customLink';

const Footer = () => {

    const footerLinks = [
        {id: 'consultaion', title: 'КОНСУЛЬТАЦИИ', link: '/page/Consultation'},
        {id: 'study', title: 'ОБУЧЕНИЕ', link: '/page/Education'},
        {id: 'book', title: 'КНИГА', link: '/page/book'},
        {id: 'almanac', title: 'АЛЬМАНАХ', link: '/page/almanac'},
        {id: 'stars', title: 'ЗНАМЕНИТОСТИ', link: '/page/stars'},
        {id: 'video', title: 'ВИДЕО', link: '/page/video'},
        {id: 'students', title: 'КУРСЫ', link: '/page/students'},
    ]
    
    return (
        <div className='footer'>
            {footerLinks.map((link) =>
                <CustomLink link={link}  key={link.id}></CustomLink> 
            )}
        </div>
    );
}

export default Footer;
