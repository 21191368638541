import React from "react"
import classes from '../Calc.module.css'

import ForecastPeriod from "./ForecastPeriods";
import ForecastYears from "./ForecastYears";

const FullForecast = ({periods, years, changePeriod, changeYear }) => {
    return (
        <div className={periods.length === 0 ? classes.forecastSimple : classes.forecast}>
            <ForecastPeriod periods={periods} changePeriod={changePeriod} />
            <ForecastYears years={years} changeYear={changeYear} />
        </div>
    )
};

export default FullForecast;
