import { useRef, useState, useEffect, useContext } from 'react';
import axios from '../../api/axios';

import AuthContext from '../../context/AuthProvider';


const Login = () => {
	
	const LOGIN_URL = '/profile/auth';

	const { setAuth } = useContext(AuthContext);
	const userRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [token, setToken] = useState('');

	const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

	useEffect(() => {
		userRef.current.focus();
	}, []);

	useEffect(() => {
		setErrMsg('');
	}, [email, pwd]);

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			const response = await axios.post(
				LOGIN_URL,
				JSON.stringify({ 
					'email': email,
					'password': pwd
				}),
				{ headers: { 'Content-Type': 'application/json' }}
			);
			const accessToken = response?.data?.accessToken;
			setToken(response?.data?.accessToken)
			localStorage.setItem('token', accessToken)
			const roles = response?.data?.role;
			setAuth({ user: email, pwd, roles, accessToken });
			setEmail('');
			setPwd('');
			localStorage.setItem('lang', response.data.lang)
			window.location.href = '/'
		} catch (err) {
			if (!err?.response) {
				setErrMsg('Ошибка сервера');
			} else if (err.response?.status === 401) {
				setErrMsg('Неверный пароль');
			} else if (err.response?.status === 402) {
				setErrMsg('Неверный email');
			}
			errRef.current.focus();
		}
	};

	return (
		<div className='form__cotainer'>
				<section>
					<p
						ref={errRef}
						className={errMsg ? 'errmsg' : 'offscreen'}
						aria-live="assertive"
					> {errMsg} </p>
					<h1>Войти</h1>
					<form onSubmit={handleSubmit}>
						<label htmlFor="email">Почта:</label>
						<input
							type="text"
							id="email"
							name="email"
							ref={userRef}
							autoComplete="off"
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							required
							placeholder='Почта'
						/>

						<label htmlFor="password">Пароль:</label>
						<input
							type={showPassword ? 'text' : 'password'}
							id="password"
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
							required
							placeholder='Пароль'
						/>

						<div className='showPwd' onClick={toggleShowPassword}>
							<input
								type="checkbox"
								onChange={toggleShowPassword}
								checked={showPassword}
							/>
							<label htmlFor="showPassword">Показать пароль</label>
						</div>
						<button className='customBtn'>Войти</button>
						
					</form>

					<div className='line__container'>
						<span className="line backBtn">
							<a href="/page/register"> Зарегистрироваться</a>
						</span>

						<span className="line backBtn">
							<a href="/page/restore"> Восcтановить пароль</a>
						</span>
					</div>


					
				</section>

				<span className="line backBtn">
					<a href="/"> Назад </a>
				</span>
		</div>
	);
};

export default Login;
