import React from "react"
import { Link } from "react-router-dom";

import classes from './HoverItem.module.css'

const HoverItem = ({visible, data, link}) => {
    return (
        <>
            {visible &&
                <div className={classes.tooltip}>
                    {data}
                    <Link
                        to={{
                            pathname: '/page/book',
                            search: `data=${link}`,
                        }}
                        target="_blank"
                    >
                        <p className={classes.link}>Подробнее</p>
                    </Link>
                </div>
            }
        </>
    )
};

export default HoverItem;
