import React from 'react'
import classes from './items.module.css'
import getDate from '../../../api/getDate';

const StarsItem = ({star}) => {

    const formattedText = star.description.replace(/\n/g, '<br>');

    return (
        <div className={classes.container}>
            <h3 className={classes.title}>{star.name} {star.familyname} {star.lastname}</h3> 
            <h5 className={classes.title}>{getDate(star.birthday)}</h5>
            <div>
                <img className={classes.starImg} src={`/img/png/stars/${star.calculation_path}`} alt={star.name} />  
                <div className={classes.starText} dangerouslySetInnerHTML={{ __html: formattedText }} />
            </div>
        </div>
    );
}

export default StarsItem;
