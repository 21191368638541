import React, {useState, useEffect} from "react"
import { NavLink, useLocation } from "react-router-dom";
import {StudentOption, option} from "../../api/krugData";
import decodeLink from "../../api/functions";
import { Link } from "react-router-dom";
import classes from './styles/calc.module.css'
import axios from '../../api/axios';
import { compareDate } from "../../api/compareDate";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Collapse from "../UI/collapse/Collapse";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import Dropdown from "../UI/dropdown/Dropdown";
import ScrollToTopButton from "../UI/button/ScrollToTopButton";

import CalcInfo from "../blocks/Calculation/CalcInfo";
import Krug from "../blocks/Calculation/Krug";
import InfoHead from "../UI/infoHead/infoHead";
import FullForecast from "../blocks/Calculation/forecast/FullForecast";
import PeriodEvent from "../blocks/Calculation/forecast/PeriodEvent";
import YearEvent from "../blocks/Calculation/forecast/YearEvent";

const Calculation = () => {

    const VARIFY_URL = '/role'
    const CALCULATE_URL = '/calculation'

    const location = useLocation();
    const token = localStorage.getItem('token')
    const [role, setRole] = useState('notAuth')
    let [lang, setLang] = useState(localStorage.getItem('lang') || 'ru' )
    const [missionLang, setMissionLang] = useState(lang)
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    const [dropdownOption, setDropDown] = useState(option)

    const [data, setData] = useState([]);
    const [gain, setGain] = useState([]);
    const [gain_e, setGain_e] = useState([]);

    const [periods, setPeriods] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState()
    const [emotionPeriods, setEmotionPeriods] = useState([])
    const [currentEmotionPeriods, setCurrentEmotionPeriods] = useState()

    const [oblik, setOblik] = useState([])
    const [telo, setTelo] = useState([])
    const [chislo, setChislo] = useState([])

    const [years, setYears] = useState([]);
    const [currentYear, setCurrentYear] = useState('')
    const [emotionYears, setEmotionYears] = useState([])
    const [currentEmotionYears, setCurrentEmotionYears] = useState('')

    const [mission, setMission] = useState('')
    const [mission_e, setMission_e] = useState('')

    const [username, setUsername] = useState(decodeLink(location.search).name || localStorage.getItem('calcName') || '')
    const [lastname, setLastname] = useState(decodeLink(location.search).lastname || localStorage.getItem('calclastname') ||  '')
    const [familyname, setFamilyname] = useState(decodeLink(location.search).familyname || localStorage.getItem('calcfamilyname') || '')
    const [birthday, setBirthday] = useState(decodeLink(location.search).day || localStorage.getItem('calcbirthday') || '')
    const [month, setMounth] = useState(decodeLink(location.search).month || localStorage.getItem('calcmonth') || '')
    const [year, setYear] = useState(decodeLink(location.search).year || localStorage.getItem('calcyear') || '')

    const [nextPeriod, setNextPeriod] = useState(false)

    function openNewPage(){
        window.open('/', '_blank');
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                    setRole(response.data.role)
                    setError(false)
                    setLoaded(true)
                if (response.data.role === 'student'){
                    setDropDown(StudentOption)
                } else (setDropDown(option))
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 422){
                    setRole('notAuth')
                    setError(false)
                    setLoaded(true)
                    setDropDown(option)
                }else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }};
            fetchData();
    }, [token]);

    const Calculate = async () =>{
        setIsLoading(true)
            let head
            try {
                role === 'notAuth' ? head = { 'Content-Type': 'application/json'} : head = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`} 
                if (lang === ''){lang = localStorage.getItem('lang') || 'ru'}
			const response = await axios.post(
				CALCULATE_URL,
				JSON.stringify({
                    'name': username,
                    'familyname': familyname,
                    'lastname': lastname,
                    'birthday': birthday,
                    'month': month,
                    'year': year,
                    'lang': lang
				}),{headers: head} 
			);
                setData(response.data.main_circle)

                setChislo(response.data.number_structure)
                setOblik(response.data.oblik_circle)
                setTelo(response.data.telo_circle)

                if (missionLang === 'e'){
                    setGain(response.data.main_gain_e)
                } else if (missionLang === 'ru_e') {
                    setGain_e(response.data.main_gain_e)
                    setGain(response.data.main_gain)
                } else {
                    setGain(response.data.main_gain)
                }

                setPeriods(response.data.calculation_eventCircle_periods)
                setCurrentPeriod(response.data.eventCircle_currentPeriod)
                setEmotionPeriods(response.data.calculation_emotionCircle_periods)
                setCurrentEmotionPeriods(response.data.emotionCircle_currentPeriod)

                setYears(response.data.calculation_eventCircle_years)
                setCurrentYear(response.data.eventCircle_currentYear)
                setEmotionYears(response.data.calculation_emotionCircle_years)
                setCurrentEmotionYears(response.data.emotionCircle_currentYear)

                localStorage.setItem('calcName', username)
                localStorage.setItem('calcfamilyname', familyname)
                localStorage.setItem('calclastname', lastname)
                localStorage.setItem('calcbirthday', birthday)
                localStorage.setItem('calcmonth', month)
                localStorage.setItem('calcyear', year)

                if (missionLang === 'e'){
                    for (let item = 0; item < response.data.main_circle.length; item++) {
                        if (response.data.main_circle[item].name === 'missiya_e'){
                            setMission(response.data.main_circle[item].value);
                            break;
                        }
                    }
                } else if (missionLang === 'ru_e') {
                    for (let item = 0; item < response.data.main_circle.length; item++){
                        if (response.data.main_circle[item].name === 'missiya'){
                            setMission(response.data.main_circle[item].value);
                        } if (response.data.main_circle[item].name === 'missiya_e'){
                            setMission_e(response.data.main_circle[item].value)
                        }
                    }
                }else {
                    for (let item = 0; item < response.data.main_circle.length; item++) {
                        if (response.data.main_circle[item].name === 'missiya'){
                            setMission(response.data.main_circle[item].value);
                            break;
                        }
                    }
                }
            setIsLoading(false)
		} catch (err) {
                alert(err.response.data.message)
                setIsLoading(false)
            }
    }

    function reset(){
        setUsername(''); setLastname(''); setFamilyname('')
        setBirthday(''); setMounth(''); setYear('')
        setData([]); setGain([]); setPeriods([])
        setYears([]); setEmotionPeriods([]); setEmotionYears([])
    }

    const handleOptionChange = (option) => {
        setLang(option)
        setMissionLang(option)
        setData([]); setGain([]); setPeriods([]);setYears([])
        setEmotionPeriods([]); setEmotionYears([])
    }

    const changePeriod = (number) => {
        setCurrentPeriod(number);
        const date1 = compareDate('21.09');
        const date2 = compareDate(`${birthday}.${month}`);
        if (number <= 8){
            date1 < date2 ? setCurrentEmotionPeriods(number+1) :  setCurrentEmotionPeriods(number)
            setNextPeriod(false)
        } else if (number = 9) {
            if (date1 < date2) {
                setCurrentEmotionPeriods(1)
                setNextPeriod(true)
            } else {
                setCurrentEmotionPeriods(number)
                setNextPeriod(false)
            }
        } else {
            setCurrentEmotionPeriods(number)
            setNextPeriod(false)
        }
    };

    const changeYear = (number) => {
        setCurrentYear(number)
        setCurrentEmotionYears(number)
    }


    const DESCRIPTION_URL = '/calculation/description';

    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [hoverDesc, setHoverDesc] = useState('');
    const [hoverLink, setHoverLink] = useState('')

    const handleClick = async ( item, event, table) => {
        const rect = event.target.getBoundingClientRect();
        const x = rect.left + window.scrollX - 20;
        const y = rect.top + window.scrollY +90;
        try {
            const response = await axios.post(
                DESCRIPTION_URL,
                JSON.stringify({
                    'table': table,
                    'name': item.name,
                    'value': item.value
                }), { headers: { 'Content-Type': 'application/json' } }
            );
            setHoverDesc(response.data.description);
            setHoverLink(response.data.name)
            setPopupPosition({ x, y });
        } catch (err) {
            alert(err.response.data.message)
        }
        
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popup = document.querySelector('.popupTool');
            if (popup && !popup.contains(event.target)) {
                setHoverDesc('')
                setHoverLink('') 
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function print() {
        window.print()
    }

    return (
            <>
                <Header />
                {loaded &&
                    <div className={role === 'notAuth' ? 'contentSimple' : 'content'}>
                        <ScrollToTopButton />
                        <InfoHead content='ТАЙНЫ ЛИЧНОГО КОДА' onClick={openNewPage} />
                        <div className={classes.calculate}>
                            <div className={classes.calc__header}>
                                <div>
                                        <form className={classes.calcInput}>
                                            <Input 
                                                type='text'
                                                name='familyname'
                                                id='familyname'
                                                value={familyname}
                                                onChange={(e) => setFamilyname(e.target.value)}
                                                placeholder='Фамилия'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                                
                                            />
                                            <Input 
                                                type='text'
                                                name='username'
                                                id='username'
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                placeholder='Имя'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='text'
                                                id='lastname'
                                                name='lastname'
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                                placeholder='Отчество'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='birthday'
                                                name='birthday'
                                                value={birthday}
                                                onChange={(e) => setBirthday(e.target.value)}
                                                placeholder='день рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='month'
                                                name='month'
                                                value={month}
                                                onChange={(e) => setMounth(e.target.value)}
                                                placeholder='месяц рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='year'
                                                name='year'
                                                value={year}
                                                onChange={(e) => setYear(e.target.value)}
                                                placeholder='год рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                        </form>

                                    <div className={classes.buttons}>
                                        <Dropdown option={dropdownOption} handleOptionChange={handleOptionChange} lang={lang} />

                                        <Button 
                                            className='calc'
                                            onClick={Calculate}
                                            disabled={isLoading}
                                        ><span>Посчитать</span>
                                        </Button>

                                        <Button 
                                            className='clean'
                                            onClick={reset}
                                        ><span>Сбросить</span>
                                        </Button>
                                    </div>
                                    <CalcInfo />
                                </div>
                                <button className="printBtn" onClick={print} >Печать</button>
                            </div>

                            <div className={classes.calcBlock} id="print" >
                                {data.length !== 0 && role !== 'notAuth' &&
                                    <div className={classes.calcCard}>
                                        <h4>КАРТА ОБЛИКА</h4>
                                        <div className='krug__oblik'>
                                            {oblik.map((item, index) => {
                                                    return <div key={index}
                                                                onClick={(event) => handleClick(item, event, 'OblikCircle')}
                                                                className={`${item.name}__oblik`}
                                                            >
                                                                <span>{item.value}</span>
                                                            </div>
                                                } 
                                            )} 
                                        </div>
                                        <h4> СТРУКТУРА ЧИСЛА</h4>
                                        <div className='krug__chislo'>
                                            {chislo.map((item, index) => {
                                                    return <div key={index}
                                                                onClick={(event) => handleClick(item, event, 'NumberStructure')}
                                                                className={item.name}
                                                            >
                                                                <span>{item.value}</span>
                                                            </div>
                                                } 
                                            )} 
                                        </div>
                                        <h4>КАРТА ТЕЛА</h4>
                                        <div className='krug__telo'>
                                            {telo.map((item, index) => {
                                                    return <div key={index}
                                                                onClick={(event) => handleClick(item, event, 'TeloCircle')}
                                                                className={`${item.name}__telo`}
                                                            >
                                                                <span>{item.value}</span>
                                                            </div>
                                                } 
                                            )}
                                        </div>
                                    </div>  
                                }
                                <Krug data={data} role={role} mission={mission} misssion_e={mission_e}  gain={gain} gain_e={gain_e} lang={lang} className='krug' container='calculation' popUpLink='Circle' />
                            </div>
                            
                        </div> 

                        {hoverDesc && (
                        <div className="popupTool" style={{ top: popupPosition.y, left: popupPosition.x }}>
                            {hoverDesc}
                            <Link
                                to={{
                                    pathname: '/page/book',
                                    search: `data=${hoverLink}`,
                                }}
                                target="_blank"
                            >
                                <p className='popLink'>Подробнее</p>
                            </Link>
                        </div>
                        )}

                        {isLoading &&
                        <div className="spinner_box"><div className="spinner">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div></div>
                        }

                        {role === 'notAuth' ?
                            <div className="closed">
                                <InfoHead content='ПРОГНОЗ' />
                                <NavLink style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}} to='/page/login'>
                                    Полная версия расчётов доступна после регистрации
                                </NavLink>
                                <InfoHead content='ПОЛНЫЙ ПРОГНОЗ' />
                                <NavLink style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}} to='/page/login'>
                                    Полная версия расчётов доступна после регистрации
                                </NavLink>
                            </div>
                        :
                            <div>
                                <div>
                                    <InfoHead content='ПРОГНОЗ' />
                                    <Collapse className='collapse'>
                                    <div className={data.length === 0 ? classes.forecastSimple : classes.forecast}>
                                            {year !== '' && month !== '' && birthday !== '' &&
                                                <>
                                                    <PeriodEvent periods={periods} current={currentPeriod} emotions={emotionPeriods} currentEmotion={currentEmotionPeriods} nextPeriod={nextPeriod} role={role} lang={lang} birthday={`${birthday}.${month}`} id="print" />
                                                    <YearEvent years={years} current={currentYear} emotion={emotionYears} currentEmotion={currentEmotionYears} role={role} lang={lang} birthday={`${birthday}.${month}`} id="print" /> 
                                                </>
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                            
                                <div >
                                    <InfoHead content='ПОЛНЫЙ ПРОГНОЗ' />
                                    <Collapse className='collapse'>
                                        {year !== '' && month !== '' && birthday !== '' &&
                                            <FullForecast periods={periods} years={years} changePeriod={changePeriod} changeYear={changeYear} id="print" />
                                        }
                                    </Collapse>
                                </div>
                            </div>
                        }
                    </div>
                } 

                {error &&
                    <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                    Ошибка соединения с сервером</p>
                }
            
                <Footer />
            </>
    )
};

export default Calculation;
