import React from "react"
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import InfoHead from "../UI/infoHead/infoHead";

const Author = () => {
    return (
        <>
            <Header  />
            <div className="contentSimple">
                <InfoHead content='О СИСТЕМЕ ТАЙНЫ ЛИЧНОГО КОДА И ОБ АВТОРЕ' />
                <div className="book">
                    <div className="leftPage author">
                        <h2 className="title">Трансформационная Терапевтическая система декодирования личности </h2> <h2 className="title"> "ТАЙНЫ ЛИЧНОГО КОДА" </h2>
                        <img src='/img/png/krug.png' alt="krug" />
                        <p>Система Декодирования Личности «ТАЙНЫ ЛИЧНОГО КОДА» основана на анализе всех чисел, полученных из Фамилии, Имени, Отчества и Даты Рождения человека, и даёт полное понимание личности, а также раскрывает потенциалы человека. Она объединяет в себе знания из классической нумерологии, нумерологии Пифагора, ненумерологии, матрицы судьбы, астрологии, дизайна человека и психологии.</p>
                        <p>С её помощью можно понять, как решать любые задачи в течение жизни. Это трансформационная терапевтическая работа с собой и окружающими. Помимо этого, большим плюсом является возможность максимально точно прогнозировать события. Кроме того, Система Декодирования Личности довольно просто и понятно описывает совместимость людей друг с другом. </p>
                        <p>Зная Карту Личности человека, вы можете узнать все его характеристики и качества, даже если видите этого человека впервые в жизни. Система Декодирования Личности помогает понять, что за люди находятся рядом с вами на самом деле. Не нужно теперь строить догадки и предположения. Не нужно накладывать на ребёнка, мужа или жену, близких людей рамки и ограничения своего восприятия. Благодаря данной системе, можно просто воспринимать человека таким, какой он есть и понимать, почему он поступает так или иначе. И самое главное, можно помочь людям так, как необходимо именно им.</p>
                        <p>Зная всё о человеке, можно, используя нужные рычаги, помочь избавиться от эмоциональных травм, ограничивающих установок и добиться высот в тех сферах жизни, которые имеют важность. </p>
                        <p>Система максимально точная, это математические закономерности, выявленные на исследовании огромного количества различных людей. Она даёт возможность читать человека и его жизнь как открытую книгу. </p>
                        <p>Карта Личности – это инструкция к вашей жизни. Научившись её расшифровывать, вы сможете стать счастливее, улучшив все сферы своей жизни – доход, здоровье, семью, отношения, энергию. Анализ карты расставляет всё по своим местам. Вы легко сможете определить свои цели и пути достижения успеха.</p>
                    </div>
                    <div className="rightPage">
                        <p style={{fontWeight: 'bold'}}>Знание Системы «ТАЙНЫ ЛИЧНОГО КОДА» помогает улучшить вашу жизнь</p>
                        <p>Во-первых, это позволит вам увидеть себя настоящего, что поможет, если вы находитесь на пути личной трансформации.</p>
                        <p>Во-вторых, она покажет вам, как посмотреть на свою жизнь глубже и шире. Это поможет вам проанализировать свои проблемы и увидеть их как возможности для роста, которые необходимы для развития.</p>
                        <p>Система научит вас тому, что независимо от того, насколько сложными могут быть ваши проблемы, вы родились способными преодолевать их. Больше всего она поможет вам понять и принять себя.</p>    
                        <p>Благодаря Системе «ТАЙНЫ ЛИЧНОГО КОДА» вы сможете распознать свои недостатки и увидеть свои суперспособности, чтобы начать совершенствовать себя и создавать лучшую жизнь. Знание вашей судьбы, жизненной цели и потенциала вдохновит вас стать лучшей версией себя. Управлять своей жизнью станет максимально легко и просто.</p>
                        <p>Автор данного метода анализа личности – Слада Новицки. Изучив и проанализировав огромное количество данных о разных людях, она выявила определённые закономерности, которые объединила в максимально точную систему. С помощью этой системы уже более тысячи клиентов и учеников Слады получили самую мощную силу – Знания. Информацию о себе и об окружающих людях. А кто владеет информацией, тот владеет миром!</p>
                        <p>Добро пожаловать в изучение системы «ТАЙНЫ ЛИЧНОГО КОДА». Начиная с этого момента, ваша жизнь уже не будет прежней! </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
};

export default Author;