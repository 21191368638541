import React, { useState, useEffect } from "react"
import axios from '../../api/axios';

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import ExtraItem from "../blocks/items/ExtraItem";
import MainItem from "../blocks/items/MainItem";
import InfoHead from "../UI/infoHead/infoHead";

const Study = () => {

    const STUDY_URL = '/study/get?level=main'
    const EXTRA_STUDY_URL = '/study/get?level=extra'
    const [loaded, setLoaded] = useState(true)
    const [error, setError] = useState(false)

    const [extraStudy, setExtaStudy] = useState([])
    const [study, setStudy] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(STUDY_URL);
                setStudy(response.data);
                const extra__response = await axios.get(EXTRA_STUDY_URL);
                setExtaStudy(extra__response.data);
                setLoaded(true)
                setError(false)
            } catch (error) {
                if (error.message === 'Network Error'){
                    setError(true)
                    setLoaded(false)
                } else {
                    console.log(error.message);
                }
            }
        };
        fetchData();
    }, []);

    return (
            <>
                <Header />
                {loaded && 
                    <div className="contentSimple">
                        <InfoHead content='ОБУЧЕНИЕ' />
                        <div className="book">
                            <div className="leftPage">
                                <h2>Основные курсы</h2>
                                {study.length === 0 ?
                                    <p><i>Доступных курсов пока нет</i></p> 
                                    : study.map( (item, index) => 
                                    <MainItem data={item} key={index} />
                                )}
                            </div>
                            <div className="rightPage">
                                <h2>Дополнительные курсы</h2>
                                {extraStudy.length === 0 ?
                                    <p><i>Доступных курсов пока нет</i></p> 
                                    : extraStudy.map((item, index) => 
                                        <ExtraItem data={item} key={index} />
                                )}
                            </div>
                        </div>
                    </div>
                }
                {error &&
                    <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                    Ошибка соединения с сервером</p>
                }
                <Footer />
            </>
    )
};

export default Study;