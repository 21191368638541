import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import axios from '../../api/axios';

import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import Table from '../UI/table/Table';
import ScrollToTopButton from '../UI/button/ScrollToTopButton';

const Archive = () => {

    const VARIFY_URL = '/role'
    const token = localStorage.getItem('token')
    const [role, setRole] = useState('notAuth')

    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setError(false)
                setLoaded(true)
            } catch (error) {
                if(error.response?.status === 401 || error.response?.status === 422){
                    setRole('notAuth')
                    setError(false)
                    setLoaded(true)
                } else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }
            };
            fetchData();
    }, [token]);

    return (
        <>
        <Header  />

        {loaded &&
            <>
                <div className="contentSimple">
                    <ScrollToTopButton />
                    {role === 'notAuth' ? 
                        <div className='noAccess'>
                            <p>Для получения доступа к архиву необходимо приобрести курс или зарегистрироваться</p>
                            <p><i><NavLink to='/page/register' className='link' > зарегистрироваться / войти в аккаунт</NavLink></i></p>
                            <p><i><NavLink to='/page/Education' className='link' > посмотреть список доступных курсов</NavLink></i></p>
                        </div>  : 
                            <Table role={role} />
                    }
                </div>
            </>
        }

        {error &&
            <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
            Ошибка соединения с сервером</p>
        }
        
        <Footer />
        </>
        
    );
}

export default Archive;
