import React from 'react';
import classes from './infoHead.module.css'

const InfoHead = ({content, onClick}) => {
    return (
        <div className={classes.info__header} onClick={onClick}>
            {content}
        </div>
    );
}

export default InfoHead;
