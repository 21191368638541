export default function decodeLink(link){
    const paramsString = link.startsWith("?") ? link.slice(1) : link;
    const paramsArray = paramsString.split("&&");
    const decodedParams = {};
    paramsArray.forEach(param => {
        const [key, value] = param.split("=");
        if (key === 'birthday'){
            const birthday = value.split('.')
            decodedParams['day'] = birthday[0]
            decodedParams['month'] = birthday[1]
            decodedParams['year'] = birthday[2]
        }
        decodedParams[key] = decodeURIComponent(value);
    });
    return decodedParams
}

