import React from "react"
import classes from '../Calculation/Calc.module.css'

import MatchCard from "./MatchCard";

const CardsMatch = ({cards}) => {
    return (
        <div className={classes.cards__container}>
            <h2>СОВМЕСТИМОСТЬ </h2>
            {cards.length !== 0 ?
                cards.map((item,index) => (
                    <MatchCard key={index} card={item} />
                ))
                : <p><i>Совместимостей нет</i></p>
            }
            
        </div>
    )
};

export default CardsMatch;
