import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {

        window.pageYOffset > 300 ? setIsVisible(true) : setIsVisible(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth',
        });
    };

    return (
        <div className="scroll-to-top">
        {isVisible && (
            <button className="scroll-to-top-button" onClick={scrollToTop} title="Наверх">
                наверх
            </button>
        )}
        </div>
    );
};

export default ScrollToTopButton;
