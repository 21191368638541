import React from "react"
import classes from '../rightPage/items.module.css'

const VideoBlock = ({video}) => {

    const formattedText = video.description.replace(/\n/g, '<br>');

        return (
            <div className={classes.container}>
                <h3 className={classes.title} >{video.title}</h3> 
                <div>
                <iframe 
                    style={{float: 'left', margin: '10px'}} 
                    width="430" height="300" 
                    src={`https://www.youtube.com/embed/${video.link}`}
                    frameBorder="0" 
                    allowFullScreen
                    title={`${video.title}`}
                    ></iframe>

                <div className={classes.description} dangerouslySetInnerHTML={{ __html: formattedText }} />

                </div>
            </div>
        )
};

export default VideoBlock;