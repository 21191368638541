import axios from "../../api/axios";
import { useRef, useState, useEffect } from 'react';
import {faCheck, faTimes, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PWD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,24}$/;

const NewPWD = () => {

    const [token, setToken] = useState('')

    const [id, setId] = useState('')
    const [loaded, setLoaded] = useState(false)

	const errRef = useRef();
	const [errMsg, setErrMsg] = useState('');

    const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
		setValidPwd(PWD_REGEX.test(pwd));
		setValidMatch(pwd === matchPwd);
	}, [pwd, matchPwd]);

	useEffect(() => {
		setErrMsg('');
	}, [ pwd, matchPwd]);

    useEffect(() => {
        const url = window.location.href;
        const parts = url.split('/');
        
        setToken(parts[parts.length - 1])

        const fetchData = async () => {
            try {
                const response = await axios.get(`/profile/reset_password/${parts[parts.length - 1]}`);
                setId(response.data.user_id);
                if(response.status === 200){
                    setLoaded(true)
                }
            } catch (error) {
                if (error.response?.status !== 200){
                    alert(error.response.data.message)
			        window.location.href = '/'
                } 
            }};
            fetchData();
        }
    , []);

    const handleSubmit = async (e) => {
		e.preventDefault();

		const v2 = PWD_REGEX.test(pwd);
		if (!v2) {
			setErrMsg('Ошибка входа');
			return;
		}
		try {
			const response = await axios.post(
				`/profile/${id}/change_password`,
				JSON.stringify({
					'password': pwd,
                    'token': token
				}),{headers: { 'Content-Type': 'application/json' }}
			);
			setPwd('');
			setMatchPwd('');
            if (response.status === 200){
			    window.location.href = '/page/login'
            }
		} catch (err) {
			if (!err?.response) {
				setErrMsg('Ошибка сервера');
			}
			errRef.current.focus();
		}
	};

    return (
        <>
            {loaded &&
                <div className='form__cotainer'>
                    <section >
                        <p
                            ref={errRef}
                            className={errMsg ? 'errmsg' : 'offscreen'}
                            aria-live="assertive"
                        >
                            {errMsg}
                        </p>
                        
                        <h1>Новый пароль</h1>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="password">
                                    Пароль:
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={validPwd ? 'valid' : 'hide'}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className={validPwd || !pwd ? 'hide' : 'invalid'}
                                    />
                                </label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    aria-invalid={validPwd ? 'false' : 'true'}
                                    aria-describedby="pwdnote"
                                    onFocus={() => setPwdFocus(true)}
                                    onBlur={() => setPwdFocus(false)}
                                    placeholder='Пароль'
                                />
                                
                                <p
                                    id="pwdnote"
                                    className={pwdFocus && !validPwd ? 'instructions' : 'offscreen'}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    От 6 до 24 символов.
                                    <br />
                                    Пароль должен содержать буквы и цифры
                                </p>
        
                                <div className='showPwd' onClick={toggleShowPassword}>
                                    <input
                                        type="checkbox"
                                        onChange={toggleShowPassword}
                                        checked={showPassword}
                                    />
                                    <label htmlFor="showPassword">Показать пароль</label>
                                </div>
        
                                <label htmlFor="confirm_pwd">
                                    Подтвердить пароль:
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={validMatch && matchPwd ? 'valid' : 'hide'}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className={validMatch || !matchPwd ? 'hide' : 'invalid'}
                                    />
                                </label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="confirm_pwd"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    aria-invalid={validMatch ? 'false' : 'true'}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                    placeholder='Повторите пароль'
                                />
                                <p
                                    id="confirmnote"
                                    className={matchFocus && !validMatch ? 'instructions' : 'offscreen'}
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    Пароли не совпадают
                                </p>
        
                                <button
                                    className='customBtn register'
                                    disabled={ !validPwd || !validMatch ? true : false}
                                >
                                    Задать новый пароль
                                </button>
                            </form>
                            <div className='line__container'>
                                <span className="line backBtn">
                                    <a href="/page/login"> Войти в профиль</a>
                                </span>
        
                                <span className="line backBtn">
                                    <a href="/"> Назад </a>
                                </span>
                            </div>
                        </section>
                </div>
            }
        </>
    )
};

export default NewPWD;
