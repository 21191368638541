import React, { useState, useEffect, useRef } from "react";
import classes from '../Calc.module.css';
import HoverItem from "../../../UI/hoverItem/HoverItem";

const GainItem = ({ item }) => {
    
    const [visibleItem, setVisibleItem] = useState(false);
    const containerRef = useRef(null);

    const openPopUp = () => {
        setVisibleItem((prevVisible) => !prevVisible);
    };

    useEffect(() => {
        const outsideClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setVisibleItem(false);
        }
        };
        document.addEventListener("click", outsideClick);
        return () => {
            document.removeEventListener("click", outsideClick);
        };
    }, []);

    return (
        <div ref={containerRef} className={classes.gain}>
            <img src={`/img/png/gain/${item.image_path}`} alt='gain' />
            <div onClick={openPopUp}>
                <h5 style={{ color: item.color }}>{item.title.toUpperCase()}</h5>
                <p style={{ color: item.color }} className={classes.gainText}>{item.gain}</p>
            </div>
            {item.description !== null && (
                <div className={classes.box}>
                    <HoverItem
                        visible={visibleItem}
                        data={item.description}
                        link={item.title}
                    />
                </div>
            )}
        </div>
    );
};

export default GainItem;
