import React, {useState, useEffect} from 'react';
import axios from '../../api/axios';
import classes from './styles/profile.module.css'
import {option, StudentOption} from '../../api/krugData';
import InputMask from 'react-input-mask';

import Header from '../blocks/header/Header'
import Footer from '../blocks/footer/Footer';
import Button from '../UI/button/Button';
import Input from '../UI/input/Input';
import Dropdown from '../UI/dropdown/Dropdown';

import SupportItem from '../blocks/support/supportBlock';
import InfoHead from '../UI/infoHead/infoHead';

const Home = () => {

  const HOME_URL = '/profile'
  const LOGOUT_URL = '/profile/logout'
  const SUPPORT_URL = '/support/get'
  const USER_UPDATE = '/profile/update'

  const token = localStorage.getItem('token')
  const [lang, setLang] = useState('')
  const [isAuth, setIsAuth] = useState(false)
  const [dropdownOption, setDropDown] = useState(option)
  const [role, setRole] = useState('')

  const [username, setUsername] = useState('')
  const [lastname, setLastname] = useState('')
  const [familyname, setFamilyname] = useState('')
  const [birthday, setBirthday] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const [support, setSupport] = useState([])
  const [searchLine, setSearchLine] = useState('');
  const [foundItem, setfoundItem] = useState(support);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(HOME_URL, { 
              headers: {Authorization: `Bearer ${token}`},
            });
            setIsAuth(true)
            setRole(response.data.role)
            setLang(response.data.lang)
            setEmail(response.data.email)

            response.data.familyname === null ? setFamilyname('') : setFamilyname(response.data.familyname)
            response.data.lastname === null ? setLastname('') : setLastname(response.data.lastname)
            response.data.username === null ? setUsername('') : setUsername(response.data.username)
            response.data.birthday === null ? setBirthday('') : setBirthday(response.data.birthday)
            response.data.phone === null ? setPhone('') : setPhone(response.data.phone)
            response.data.role === 'student' ? setDropDown(StudentOption) : setDropDown(option)

          } catch (error) {
              if(error.response?.status !== 200) {
                window.location.href = '/page/login'
              }  
            }
          };
        fetchData();
      }, [token]);

      useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get(SUPPORT_URL);
              setSupport(response.data);
            } catch (error) {
            }
          };
        fetchData();
      }, []);

    const filter = (e) => {
      const keyword = e.target.value.toLowerCase();
      if (keyword !== '') {
          const results = support.filter((item) => {
              return item.question.toLowerCase().includes(keyword);
          });
          setfoundItem(results);
      } else {
          setfoundItem(support);
      }
      setSearchLine(keyword);
  };

    const Logout = async () => {
        try{
          const response = await axios.get(LOGOUT_URL, {
            headers: {Authorization: `Bearer ${token}`},
          })
          if (response.status === 200){
            localStorage.setItem('lang', 'ru')
            window.location.href = '/page/login'
          }
        } catch (error){
        }
    }

    function updateData(){
      let newData = { username: username, lastname: lastname, familyname: familyname, birthday: birthday, phone: phone, email:email, lang:lang };
      axios.put(USER_UPDATE, newData, {
        headers: {Authorization: `Bearer ${token}`}
      })
        .then(response => {
          if (response.status === 200){
            localStorage.setItem('token', response.data.accessToken)
            alert(response.data.message)
          }
        })
        .catch(error => {
          alert('Произошла ошибка при обновлении данных')
        });
    }

    const handleOptionChange = (option) => {
      setLang(option)
      localStorage.setItem('lang', option)
    }

    return (
      <>
        {isAuth && 
        <>
          <Header />
          <div className="contentSimple">
            <InfoHead content='ЛИЧНЫЙ КАБИНЕТ'/>
              <div className="book">
                <div className="leftPage">
                  <h2>Личные данные</h2>
                  <div className={classes.profile}>
                    <div className={classes.profileItem}>
                      <p>Статус: </p> 
                      {role === 'AuthUser' ? <span>Зарегистрированный пользователь</span> : <span>Студент</span> }
                    </div>
                    <div className={classes.profileItem}>
                      <p>Фамилия: </p> 
                      <Input 
                        type='text'
                        id='familyname'
                        name='familyname'
                        value={familyname}
                        onChange={(e) => setFamilyname(e.target.value)}
                        placeholder='Фамилия'
                      ></Input>
                    </div>
                    <div className={classes.profileItem}>
                      <p>Имя: </p> 
                      <Input 
                        type='text'
                        id='username'
                        name='username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='Имя'
                      ></Input>
                    </div>
                    <div className={classes.profileItem}>
                      <p>Отчество: </p> 
                      <Input 
                        type='text'
                        id='lastname'
                        name='lastname'
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        placeholder='Отчество'
                      ></Input>
                    </div>
                    <div className={classes.profileItem}>
                      <p>Дата рождения: </p> 
                      <Input 
                        type='date'
                        id='birthday'
                        name='birthday'
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                      ></Input>
                    </div>
                    <div className={classes.profileItem}>
                      <p>Телефон: </p> 
                      <InputMask
                            id='phone'
                            name='phone'
                            mask="+7-999-999-99-99"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Телефон"
                        />
                    </div>
                    <div className={classes.profileItem}>
                      <p>Почта: </p> 
                      <Input 
                        type='email'
                        id='email'
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Почта'
                      ></Input>
                    </div>

                    <div className={classes.profileItem}>
                      <p>Язык: </p>
                        <Dropdown option={dropdownOption} handleOptionChange={handleOptionChange} page='profile' langague={lang}/> 
                    </div>

                    <Button 
                      className='customBtn'
                      onClick={updateData}
                    >
                      <span>Сохранить</span>
                    </Button>
                  
                    <Button 
                      className='customBtn'
                      onClick={Logout}
                    ><span>Выйти из профиля</span>
                    </Button>
                  </div>
                </div>
                <div className="rightPage">
                  <h2>Поддержка</h2>
                  <p>По возникшим вопросам обращайтесь на почту support@kodmudrosti.ru</p>
                  <div className='header__addBtn'>
                        <Input 
                            type='search'
                            placeholder='Найти...'
                            onChange={(e) => filter(e)}
                        />
                    </div>
                        <div>
                            {foundItem.length === 0 && searchLine === '' ? 
                                (support.map((item, index) => (
                                    <SupportItem item={item} key={index} keyword={searchLine} ></SupportItem> 
                                ))) : foundItem.map((item, index) => (
                                    <SupportItem item={item} key={index} keyword={searchLine} ></SupportItem>))}

                            {foundItem.length === 0 && searchLine !== '' && 
                              <p><i>Ничего не найдено...</i></p>}

                            {support.length === 0 && <p><i>Здесь пока ничего нет</i></p>}

                        </div>

                </div>

              </div>
          </div>
          <Footer />
        </>
        }
      </>
    );
}

export default Home;
