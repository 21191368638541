import React from 'react';
import classes from './support.module.css'

const SupportItem = ({item, keyword}) => {

    const highlightKeyword = (text, keyword) => {
        const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
        return parts.map((part, index) => {
            if (part.toLowerCase() === keyword.toLowerCase()) {
                return <span key={index} className={classes.highlight}>{part}</span>;
            } else {
                return part;
            }
        });
    };

    return (
        <div className={classes.support_block}>
            <h3>{highlightKeyword(item.question, keyword)}</h3>
            <p>{item.answer}</p>
        </div>
    );
}

export default SupportItem;

