import React, { useState } from 'react';
import './DropDown.css'

function Dropdown({ handleOptionChange, option, className, page, langague}) {
  let lang

  page !== 'prifle' ? lang = localStorage.getItem('lang') : lang = langague


  const [selectedOption, setSelectedOption] = useState(lang);

  const handleChange = (event) => {

    let option = event.target.value

    // const english = ['de', 'fr', 'ned']
    // const group2 = ['serbian']
    // const group3 = ['swed']
    // english.includes(event.target.value) ? option = 'en' : option = event.target.value
    // group2.includes(event.target.value) ? option = 'me' : option = event.target.value
    // group3.includes(event.target.value) ? option = 'fi' : option = event.target.value

    const selectedOption = event.target.options[event.target.selectedIndex];
    const dataName = selectedOption.getAttribute('data-name');
    setSelectedOption(dataName);
    handleOptionChange(option, selectedOption.innerText, dataName);
  };

  return (
    <div className={className}>
        <select value={selectedOption} onChange={handleChange}>
            {option.map((item, index) => {
                return <option key={index} value={item.value} data-name={item.name}>{item.title}</option>
              } 
            )}
        </select>    
    
    </div>
  );
}

export default Dropdown;