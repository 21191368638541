import React from "react"
import Krug from "../Krug";
import classes from '../Calc.module.css'

const YearEvent = ({years, current, emotion, currentEmotion, role, lang, birthday }) => {

    let eventPeriod
    let emotionPeriod
    let eventDuration = []
    let emotionDuration = []
    let eventGain = []
    let emotionGain = []

    if (years[current] !== undefined){
        for (let item=0; item < years[current].length; item++){
            if (years[current][item].name === 'god'){
                eventPeriod = (years[current][item].value);
            } else if (years[current][item].name === 'circle_year'){
                eventDuration = years[current][item].value;
            } else if (years[current][item].name === 'eventCircle_gain'){
                eventGain = years[current][item].value
            }
        }
    }

    if (emotion[currentEmotion] !== undefined){
        for (let item=0; item < emotion[currentEmotion].length; item++){
            if (emotion[currentEmotion][item].name === 'god'){
                emotionPeriod = (emotion[currentEmotion][item].value);
            } else if (emotion[currentEmotion][item].name === 'emotionCircle_gain'){
                emotionGain = emotion[currentEmotion][item].value
            } else if (emotion[currentEmotion][item].name === "circle_year"){
                emotionDuration = emotion[currentEmotion][item].value
            }
        }
    }

    return (
        <div className={classes.forecast__container}>
            <h2>ГОД</h2>
            <div className={classes.event}>
                <div>
                    {years[current] !== undefined &&
                        <div>
                            <h3>СОБЫТИЯ</h3>
                            <p>с 21.09.{eventDuration - 1} по 21.09.{eventDuration}</p>
                            <Krug data={years[current]} role={role} lang={lang} mission={eventPeriod} misssion_e='1' page='emotions' gain={eventGain}  className='krug__year' popUpLink='Year'  /> 
                        </div>
                    }
                </div>

                <div>
                    {emotion[currentEmotion] !== undefined &&
                        <div>
                            <h3>ЭМОЦИИ</h3>
                            <p>с {birthday}.{emotionDuration - 1} по {birthday}.{emotionDuration}</p>
                            <Krug data={emotion[currentEmotion]} role={role} lang={lang} mission={emotionPeriod} misssion_e='1' page='emotions' gain={emotionGain}  className='krug__year' popUpLink='Year' /> 
                        </div>
                    }

                </div>
            </div>
        </div>
    )
};

export default YearEvent;
