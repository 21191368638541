import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';
import { tableHeaders, list } from '../../../api/krugData';
import InfoPopUp from '../popUp/InfoPop';
import Button from '../button/Button';

import classes from './table.module.css';
import Input from '../input/Input';
import InfoHead from '../infoHead/infoHead';

const Table = ({ role }) => {

    const token = localStorage.getItem('token');
    const [searchInput, setSearchInput] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [parametr, setParament] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const [modalActive, setModalActive] = useState(false)
    const [idToDelete, setIdToDelete] = useState('');
    const [loaded, setLoaded] = useState(false)

    const ARCHIVE_URL = '/profile/archive'
    const ARCHIVEDEL_URL = '/profile/archive/delete'

    const buttonRef = useRef(null);

    useEffect(() => {
        if (modalActive) {
            buttonRef.current.focus();
        }
    }, [modalActive]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await axios.get(ARCHIVE_URL, {
                    headers: {Authorization: `Bearer ${token}`}
                })
                    if(parametr === ''){
                        setData(data.data.sort((a, b) => b.id - a.id))
                        setLoaded(true)
                    }else if (list.includes(parametr)){
                        const sortedData = data.data.sort((a, b) => {
                        const compareResult = sortOrder === 'asc'
                        ? a.calculation[parametr] - b.calculation[parametr]
                        : b.calculation[parametr] - a.calculation[parametr];
                            return compareResult
                        }) 
                        setData(sortedData)
                        setLoaded(true)
                    } else if (parametr === 'birthday') {
                        const sortedData = data.data.sort((a, b) => {
                            const dateA = new Date(a.birthday).getTime();
                            const dateB = new Date(b.birthday).getTime();
                            if (sortOrder === 'asc') {return dateA - dateB;} else {return dateB - dateA;}
                        });
                        setData(sortedData);
                        setLoaded(true)
                    }else {
                        const sortedData = data.data.sort((a, b) => {
                        const compareResult = sortOrder === 'asc'
                            ? a[parametr].localeCompare(b[parametr], 'ru', { sensitivity: 'base' })
                            : b[parametr].localeCompare(a[parametr], 'ru', { sensitivity: 'base' });
                            return compareResult;
                    });
                        setData(sortedData)
                        setLoaded(true)
                    };
            } catch (error) {
                if (error.response?.status !== 200){
                    alert(error.response.data.message)
                }
            }
            };
            fetchData();
    }, [parametr, sortOrder, token]);

    useEffect(() => {
        filterData();
    }, [data, searchInput]);

    const filterData = () => {
        const filtered = data.filter(row =>
            Object.values(row).some(
                value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchInput.toLowerCase())
            )
        );
        setFilteredData(filtered);
    };

    const handleSort = parametr => {
        setParament(parametr);
        setSortOrder(prevOrder => (prevOrder === 'desc' ? 'asc' : 'desc'));
    };

    const handleDeleteRow = async () => {
        try {
            const response = await axios.post(
                ARCHIVEDEL_URL,
                JSON.stringify({ id: idToDelete }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setData(response.data);
            setModalActive(false)
        } catch (error) {
        }
    };

    return (
        
        <div className={classes.archive}>
            {loaded ?
            <>
            <InfoHead content='КАРТОТЕКА'/>
            <Input
                type="search"
                placeholder="Поиск..."
                className="search"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
            />
            <div className={filteredData.length <= 23 ? classes.pageEmpty__container : classes.page__container }>
                {filteredData.length === 0 ? (
                    <h2 style={{ color: 'white' }}>
                        Ничего не найдено
                    </h2>
                ) : (
                    <table className={classes.custom__table}>
                        <thead>
                            <tr>
                                {tableHeaders.map(header => (
                                    <th
                                        key={header.id}
                                        style={{
                                            backgroundColor: header.backgroundColor,
                                            fontSize: '16px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => handleSort(header.title)}
                                        title="Сортировать"
                                    >
                                        {header.name}
                                    </th>
                                ))}
                                <th>
                                    <img src="/img/svg/edit.svg" alt="edit" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(row => (
                                <tr key={row.id} className={classes.line}>
                                    <td className={classes.link}>
                                        <Link
                                            to={{
                                                pathname: '/',
                                                search: `redirect=true&&familyname=${row.familyname}&&name=${row.username}&&lastname=${row.lastname}&&birthday=${row.birthday}`
                                            }}
                                            title="Рассчитать"
                                            target="_blank"
                                        >
                                            {row.familyname}
                                        </Link>
                                    </td>
                                    <td className={classes.link}>
                                        <Link
                                            to={{
                                                pathname: '/',
                                                search: `redirect=true&&familyname=${row.familyname}&&name=${row.username}&&lastname=${row.lastname}&&birthday=${row.birthday}`
                                            }}
                                            title="Рассчитать"
                                            target="_blank"
                                        >
                                            {row.username}
                                        </Link>
                                    </td>
                                    <td className={classes.link}>
                                        <Link
                                            to={{
                                                pathname: '/',
                                                search: `redirect=true&&familyname=${row.familyname}&&name=${row.username}&&lastname=${row.lastname}&&birthday=${row.birthday}`
                                            }}
                                            title="Рассчитать"
                                            target="_blank"
                                        >
                                            {row.lastname}
                                        </Link>
                                    </td>
                                    <td>{row.birthday}</td>
                                    <td style={{backgroundColor: '#e3e1b0'}}>{row.calculation.missiya} / {row.calculation.fiasko}</td>
                                    {role === 'student' ? 
                                        row.calculation.missiya_e === undefined ? 
                                        <td style={{backgroundColor: '#ccdcbf'}}> - </td> :
                                        <td style={{backgroundColor: '#ccdcbf'}}>{row.calculation.missiya_e} / {row.calculation.fiasko_e}</td> 
                                        : <td style={{backgroundColor: '#ccdcbf'}}> - </td>
                                    }
                                    <td style={{backgroundColor: '#e3e1b0'}}>{row.calculation.vibor_zadacha} </td>
                                    <td style={{backgroundColor: '#e3e1b0'}}>{row.calculation.vibor_osnova} </td>
                                    <td style={{backgroundColor: '#e7a992'}}>{row.calculation.vibor_palach} </td>
                                    <td style={{backgroundColor: '#e7a992'}}>{row.calculation.sut} </td>
                                    <td style={{backgroundColor: '#d1bdbe'}}>{row.calculation.oblik} / {row.calculation.antioblik}</td>
                                    <td style={{backgroundColor: '#f2bf7e'}}>{row.calculation.telo_talant} / {row.calculation.antitelo}</td>
                                    <td style={{backgroundColor: '#f2bf7e'}}>{row.calculation.sila} / {row.calculation.slabost}</td>
                                    <td style={{backgroundColor: '#ccdcbf'}}>{row.calculation.bremya} / {row.calculation.resurs}</td>
                                    <td style={{backgroundColor: '#e3e1b0'}}>{row.calculation.vibor_energiya}</td>
                                    <td style={{backgroundColor: '#e3e1b0'}}>{row.calculation.vibor_garmoniya}</td>

                                    <td style={{backgroundColor: '#e7a992'}}>{row.calculation.vibor_poterya}</td> 
                                    <td style={{backgroundColor: '#e7a992'}}>{row.calculation.vibor_ambicii}</td>
                                    <td onClick={() => {
                                        setModalActive(true)
                                        setIdToDelete(row.id)
                                    }}>
                                        <img src="/img/svg/bin.svg" alt="bin" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>

            <InfoPopUp active={modalActive} setActive={setModalActive} >
                <div className='modal__data'>
                    <h5>Вы уверены, что хотите удалить расчёт?</h5>
                </div>
                <div className='modalBtn__content'>

                    <Button 
                        className='customBtn' 
                        ref={buttonRef} 
                        onClick={ () => handleDeleteRow() }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter'){
                                handleDeleteRow()
                            }
                        }}
                    >
                        <span>удалить</span>
                    </Button>

                    <Button className='customBtn' onClick={ () => setModalActive(false)}>
                        <span>отмена</span>
                    </Button>
                </div>
            </InfoPopUp>
            </>
            : <h3 style={{color: 'white'}} >Загрузка данных</h3>
            }
        </div>
    
    );
    
};

export default Table;
