import React, { useState, useEffect } from "react";
import axios from '../../api/axios';
import classes from '../blocks/rightPage/Video.module.css'
import { useLocation } from "react-router-dom";
import decodeLink from "../../api/functions";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Input from "../UI/input/Input";

import BookItem from "../blocks/rightPage/BookItem";
import InfoHead from "../UI/infoHead/infoHead";

const Book = () => {

    const location = useLocation();
    const receivedData = (decodeLink(location.search))

    const [loaded, setLoaded] = useState(true)
    const [error, setError] = useState(false)

    const BOOK_URL = '/Book/category'

    const [bookCategory, setBookCategory] = useState([])
    const [subcategories, setSubcategories] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});

    const [book, setBook] = useState('')
    const [emptyBook, setEmptyBook] = useState(false)

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchInput, setSearchInput] = useState(receivedData.data || '');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(BOOK_URL);
                setBookCategory(response.data);

                const subcategoriesMap = {};
                for (const category of response.data) {
                    const subcategoriesResponse = await axios.get(`/Book/${category.id}/subcategory`);
                    const sortedSubcategories = subcategoriesResponse.data.sort((a, b) => a.title.localeCompare(b.title));
                    subcategoriesMap[category.title] = sortedSubcategories;
                }
                setSubcategories(subcategoriesMap);

                setExpandedCategories({
                    [response.data[0].title]: true,
                    ...response.data.slice(1).reduce((acc, category) => {
                        acc[category.title] = false;
                        return acc;
                    }, {}),
                });
                setLoaded(true)
                setError(false)
            } catch (error) {
                if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }
        };
        fetchData();
    }, []);

    const toggleCategory = (categoryTitle) => {
        setExpandedCategories((prevExpandedCategories) => ({
            ...prevExpandedCategories,
            [categoryTitle]: !prevExpandedCategories[categoryTitle],
        }));
    };

    const categoryMatches = (category) => {
        return category.toLowerCase().includes(searchInput.toLowerCase());
    };
    

    function openDetails(item) {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/Book/${item}/video`);
                setEmptyBook(false)
                setBook(response.data)
            } catch (error) {
                if (error.response?.status === 404 ){
                    setEmptyBook(true)
                    setBook('')
                } else {
                }
            }
        };
        fetchData();
    }

    useEffect(() => {
        const filteredCategories = bookCategory.filter(category =>
            category.title.toLowerCase().includes(searchInput.toLowerCase()) ||
            (subcategories[category.title] && subcategories[category.title].some(subcategory =>
                subcategory.title.toLowerCase().includes(searchInput.toLowerCase())
            ))
        );
        const updatedExpandedCategories = {};

        if (filteredCategories.length > 0) {
            updatedExpandedCategories[filteredCategories[0].title] = true;
        }
        setFilteredCategories(filteredCategories);
        setExpandedCategories(updatedExpandedCategories);
    }, [searchInput, bookCategory, subcategories]);

    return ( 
        <>
            <Header />
            {loaded &&
                <div className="contentSimple">
                    <InfoHead content='КНИГА'/>
                    <div className="book">
                        <div className="leftPage">
                            {bookCategory.length === 0 ? 
                                <p><i>Здесь пока ничего нет</i></p> :
                                <div className="colums">
                                    <Input 
                                        type='search'
                                        placeholder='Поиск...'
                                        className='search'
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    {!filteredCategories.length ? (<p className={classes.CategoryItem}><i>Ничего не найдено</i></p>) 
                                        : (filteredCategories.map((category, index) => (
                                            <div key={index}>
                                                <div className={classes.CategoryItem} onClick={() => toggleCategory(category.title)}>
                                                    <h3>{category.title} </h3>
                                                    {expandedCategories[category.title] ? <span>свернуть</span> : <span>посмотреть</span> }
                                                </div>
                                                <hr />

                                                {expandedCategories[category.title] && subcategories[category.title] && (
                                                    <ul>
                                                        {subcategories[category.title]
                                                        .filter(subcategory => categoryMatches(subcategory.title))
                                                        .map((subcategory, index) => (
                                                            <li key={index}
                                                                onClick={() => openDetails(subcategory.id)}
                                                                className={`${searchInput && categoryMatches(subcategory.title) ? 'highlight' : ''}`}
                                                            >{subcategory.title}</li>
                                                        ))}
                                                        {subcategories[category.title].length === 0 && <span><i>Подкатегорий пока нет</i></span> }
                                                    </ul>
                                                )}
                                            </div>
                                        )))
                                    }
                                </div>
                            }
                        </div>
                        <div className="rightPage">
                            {book.length !== 0 ? <BookItem book={book} /> : <p/>}
                            {emptyBook && <p>Данных пока нет</p>}

                        </div>
                    </div>
                </div>
            }
            {error && 
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />
        </>
    )
};

export default Book;
