import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Restore from './components/pages/Restore';
import NewPWD from './components/pages/CreateNewPassword';

//header
import Profile from './components/pages/Profile'
import Author from './components/pages/Author';
import Calculation from './components/pages/Calculation'
import Match from './components/pages/Match'
import Plot from './components/pages/Plot'
import Forecast from './components/pages/Forecast'

//footer
import Consultation from './components/pages/Consultation'
import Study from './components/pages/Stydy'
import Book from './components/pages/Book';
import Archive from './components/pages/Archive';
import Stars from './components/pages/Stars'
import Video from './components/pages/Video'
import Almanac from './components/pages/Almanac';
import StudyVideo from './components/pages/StudyVideo';

import NotFound from './components/pages/NotFound';

function App() {

	return (
		<main className="App">
			<Router>
				<Routes>
					<Route path="/page/register" exact element={<Register />} />
					<Route path="/page/login" exact element={<Login/>} />
					<Route path="/page/restore" exact element={<Restore/>} />
					<Route path="/page/reset_password/:token" element={<NewPWD/>} />

					<Route path="/page/profile" element={<Profile />} />
                    <Route path="/page/author" element={<Author />} /> 
					<Route path="/" element={<Calculation />} />
					<Route path="/page/match" element={<Match />} />
					<Route path="/page/plot" element={<Plot />} />
					<Route path="/page/forecast" element={<Forecast />} />
					<Route path="/page/archive" element={<Archive />} />

					<Route path="/page/Consultation" element={<Consultation />} />
					<Route path="/page/Education" element={<Study />} />
					<Route path="/page/book" element={<Book />} />
					<Route path='/page/almanac' element={<Almanac />} />
					<Route path="/page/stars" element={<Stars />} />
					<Route path="/page/video" element={<Video />} /> 
					<Route path="/page/students" element={<StudyVideo />} />

					<Route path="*" element={<Navigate to="/not-found" />} />
					<Route path="/not-found" element={<NotFound />} />
				</Routes>
			</Router>
		</main>
	);
}

export default App;

