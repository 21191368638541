import React, {useEffect, useState} from "react"
import axios from '../../api/axios';
import classes from './styles/forecast.module.css'
import { StudentOption, option } from "../../api/krugData";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import InfoHead from "../UI/infoHead/infoHead";
import ScrollToTopButton from "../UI/button/ScrollToTopButton";
import Dropdown from "../UI/dropdown/Dropdown";
import Krug from "../blocks/Calculation/Krug";

const Forecast = () => {

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; 
    const currentYear = currentDate.getFullYear();

    const VARIFY_URL = '/role'
    const FORECAST_URL = '/calculation/forecast'

    const token = localStorage.getItem('token')
    const [role, setRole] = useState('notAuth')
    let [lang, setLang] = useState(localStorage.getItem('lang') || 'ru' )
    const [dropdownOption, setDropDown] = useState(option)
    const [loaded, setLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)

    const [username, setUsername] = useState(localStorage.getItem('forecastName') || '')
    const [lastname, setLastname] = useState(localStorage.getItem('forecastlastname') || '')
    const [familyname, setFamilyname] = useState(localStorage.getItem('forecastfamilyname') || '')
    const [birthday, setBirthday] = useState(localStorage.getItem('forecastbirthday') || '')
    const [month, setMounth] = useState(localStorage.getItem('forecastmonth') || '')
    const [year, setYear] = useState(localStorage.getItem('forecastyear') || '')

    const [chosenDate, setChosenDate] = useState(currentDay)
    const [chosenMonth, setChosenMonth] = useState(currentMonth)
    const [chosenYear, setChosenYear] = useState(currentYear)

    const [personalCard, setPersonalCard] = useState([])
    const [dayCard, setDayCard] = useState([])
    const [monthCommonCard, setMonthCommonCard] = useState([])
    const [yearCommonCard, setYearCommonCard] = useState([])
    const [periodCommonCard, setPeriodCommonCard] = useState([])
    const [monthPersonalCard, setMonthPersonalCard] = useState([])
    const [yearPersonalCard, setYearPersonalCard] = useState([])
    const [periodPersonalCard, setPeriodPersonalCard] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setLang(localStorage.getItem('lang'))
                setError(false)
                setLoaded(true)
                if (response.data.role === 'student'){
                    setDropDown(StudentOption)
                } else {setDropDown(option)}
            } catch (error) {
                if(error.response?.status === 401 || error.response?.status === 422){
                    setRole('notAuth')
                    setDropDown(option)
                    setError(false)
                    setLoaded(true)
                } else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }
        };
        fetchData();
    }, [token]);

    function reset(){
        setUsername('');setLastname('');setFamilyname('');setBirthday('')
        setMounth('');setYear(''); setChosenDate('');setChosenMonth(''); setChosenYear('');
        setPersonalCard([]); setDayCard([]); setMonthCommonCard([])
        setYearCommonCard([]); setPeriodCommonCard([])
        setMonthPersonalCard([]); setYearPersonalCard([]);setPeriodPersonalCard([])
    }

    const handleOptionChange = (option) => {
        setLang(option); setPersonalCard([]); setDayCard([])
        setMonthCommonCard([]); setYearCommonCard([]);setPeriodCommonCard([])
        setMonthPersonalCard([]);setYearPersonalCard([]);setPeriodPersonalCard([])
    }

    const Calculate = async () =>{
        setIsLoading(true)
        if (username !=='' && lastname !== '' && familyname !== '' && birthday!== '' && month !== '' && year !== ''){
            let head
            try {
                if (role === 'notAuth'){head = { 'Content-Type': 'application/json'}
                } else {head = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`}} 
                if (lang === ''){lang = localStorage.getItem('lang') || 'ru'}
			const response = await axios.post(
				FORECAST_URL,
				JSON.stringify({
                    'name': username,
                    'familyname': familyname,
                    'lastname': lastname,
                    'birthday': birthday,
                    'month': month,
                    'year': year,
                    'chosenDay': chosenDate,
                    'chosenMonth': chosenMonth,
                    'chosenYear': chosenYear,
                    'lang': lang
				}),{headers: head} 
			);

            localStorage.setItem('forecastName', username)
            localStorage.setItem('forecastfamilyname', familyname)
            localStorage.setItem('forecastlastname', lastname)
            localStorage.setItem('forecastbirthday', birthday)
            localStorage.setItem('forecastmonth', month)
            localStorage.setItem('forecastyear', year)

            if (role === 'notAuth'){
                setPersonalCard(response.data.personal_card)
                setDayCard(response.data.personal_day)

            } else if (role === 'AuthUser'){
                setPersonalCard(response.data.personal_card)
                setDayCard(response.data.personal_day)
                setMonthCommonCard(response.data.general_month)
                setYearCommonCard(response.data.general_year)
                setPeriodCommonCard(response.data.general_period)
            } else {
                setPersonalCard(response.data.personal_card)
                setDayCard(response.data.personal_day)
                setMonthCommonCard(response.data.general_month)
                setYearCommonCard(response.data.general_year)
                setPeriodCommonCard(response.data.general_period)
                setMonthPersonalCard(response.data.personal_month)
                setYearPersonalCard(response.data.personal_year)
                setPeriodPersonalCard(response.data.personal_period)
            }
            setIsLoading(false)
		} catch (err) {
                alert(err.response.data.message)
                setIsLoading(false)
            } 
        } else {
            alert('Все поля должны быть заполнены')
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header  />
            {loaded &&
                <>
                <div className="content">
                <ScrollToTopButton />

                    <InfoHead content='ПРОГНОЗ'/>
                    {isLoading &&
                        <div className="spinner_box"><div className="spinner">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div></div>
                    }
                    <div className={classes.container}>
                        <div className={classes.header}>
                            <form className={classes.calcInput}>
                                <Input 
                                    type='text'
                                    id='familyname'
                                    name='familyname'
                                    value={familyname}
                                    onChange={(e) => setFamilyname(e.target.value)}
                                    placeholder='Фамилия'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                                <Input 
                                    type='text'
                                    id='username'
                                    name='username'
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder='Имя'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                                <Input 
                                    type='text'
                                    id='lastname'
                                    name='lastname'
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    placeholder='Отчество'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                                <Input 
                                    type='number'
                                    id='birthday'
                                    name='birthday'
                                    value={birthday}
                                    onChange={(e) => setBirthday(e.target.value)}
                                    placeholder='день рождения'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                                <Input 
                                    type='number'
                                    id='month'
                                    name='month'
                                    value={month}
                                    onChange={(e) => setMounth(e.target.value)}
                                    placeholder='месяц рождения'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                                <Input 
                                    type='number'
                                    id='year'
                                    name='year'
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    placeholder='год рождения'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter'){
                                            Calculate()
                                        }
                                    }}
                                />
                            </form>

                            <div className={classes.dateInput}>
                                <h3>Выбрать дату</h3>
                                <form className={classes.calcInput}>
                                    <Input 
                                        type='number'
                                        id='birthday'
                                        name='birthday'
                                        value={chosenDate}
                                        onChange={(e) => setChosenDate(e.target.value)}
                                        placeholder='день'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter'){
                                                Calculate()
                                            }
                                        }}
                                    />
                                    <Input 
                                        type='number'
                                        id='month'
                                        name='month'
                                        value={chosenMonth}
                                        onChange={(e) => setChosenMonth(e.target.value)}
                                        placeholder='месяц'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter'){
                                                Calculate()
                                            }
                                        }}
                                    />
                                    <Input 
                                        type='number'
                                        id='year'
                                        name='year'
                                        value={chosenYear}
                                        onChange={(e) => setChosenYear(e.target.value)}
                                        placeholder='год'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter'){
                                                Calculate()
                                            }
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className={classes.buttons}>
                            <Dropdown option={dropdownOption} handleOptionChange={handleOptionChange} lang={lang} />

                            <Button 
                                className='calc'
                                onClick={Calculate}
                                disabled={isLoading}
                            ><span>Посчитать</span>
                            </Button>

                            <Button 
                                className='clean'
                                onClick={reset}
                            ><span>Сбросить</span>
                            </Button>
                        </div>

                        <div className={classes.cards}>
                            <div className={classes.cardsBlock} >
                                <div className={classes.card}>
                                    <h3>Личная карта</h3>
                                    <Krug data={personalCard} role={role} lang={lang} className='krug__middle' popUpLink='Circle' />
                                </div>

                                <div className={classes.card}>
                                    <h3>Карта дня</h3>
                                    <Krug data={dayCard} role={role} lang={lang} className='krug__day' />
                                </div>
                                {/* <div className={classes.card}>
                                    <div className={classes.description}></div>
                                </div> */}
                            </div>
                            <div className={classes.cardsBlock}>
                                <div className={classes.card}>
                                    <h3>Карта общего месяца</h3>
                                    <Krug data={monthCommonCard} role={role} lang={lang} className='krug__month' popUpLink='month' />
                                </div>

                                <div className={classes.card}>
                                    <h3>Карта общего года</h3>
                                    <Krug data={yearCommonCard} role={role} lang={lang} className='krug__year' popUpLink='Year' />
                                </div>

                                <div className={classes.card}>
                                    <h3>Карта общего периода</h3>
                                    <Krug data={periodCommonCard} role={role} lang={lang} className='krug__period' popUpLink='Period' />
                                </div>
                            </div>

                            <div className={classes.cardsBlock}>
                                <div className={classes.card}>
                                    <h3>Карта личного месяца</h3>
                                    <Krug data={monthPersonalCard} role={role} lang={lang} className='krug__month' popUpLink='month' />
                                </div>

                                <div className={classes.card}>
                                    <h3>Карта личного года</h3>
                                    <Krug data={yearPersonalCard} role={role} lang={lang} className='krug__year' popUpLink='Year' />
                                </div>

                                <div className={classes.card}>
                                    <h3>Карта личного периода</h3>
                                    <Krug data={periodPersonalCard} role={role} lang={lang} className='krug__period' popUpLink='Period' />
                                </div>
                            </div>

                            
                        </div>

                    </div>
                    </div>
                    </>
            }
            
            {error &&
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />

        </>
    )
};

export default Forecast;
