import React, { useState } from 'react';

const MainItem = ({ data }) => {
    
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const collapseStyle = {
        maxHeight: isCollapsed ? '0' : '1000px', 
        overflow: 'hidden',
        transition: 'max-height 0.3s ease',
    };

    return (
        <div style={{ width: '100%' }}>
            <div onClick={handleToggleCollapse} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3>{data.title}</h3>
                <span>{isCollapsed ? 'посмотреть' : 'свернуть'}</span>
            </div>
            <hr />
            <div style={collapseStyle}>
                <iframe
                    style={{ margin: '10px', float: 'left' }}
                    width="450"
                    height="300"
                    src={`https://www.youtube.com/embed/${data.videolink}`}
                    title={data.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
                <p style={{marginTop: '25%'}}>
                    <i>
                        <a href={data.link} target="_blank" rel="noreferrer" className="changed">
                            Перейти на сайт
                        </a>
                    </i>
                </p>
            </div>
        </div>
    );
};

export default MainItem;
