import React from 'react';

const NotFound = () => {
    return (
        <div  className='notFound'>
            <h2>Данной страницы не существует</h2>
            <span className="line backBtn">
				<a href="/"> Назад </a>
			</span>
        </div>
    );
};

export default NotFound;
