import React, {useEffect, useState} from "react"
import { NavLink } from "react-router-dom";
import axios from '../../api/axios';
import classes from './styles/match.module.css'
import {StudentOption, option} from "../../api/krugData";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Button from "../UI/button/Button";
import Input from "../UI/input/Input";
import Dropdown from "../UI/dropdown/Dropdown";
import ScrollToTopButton from "../UI/button/ScrollToTopButton";

import Krug from "../blocks/Calculation/Krug";
import BaseMatch from "../blocks/match/BaseMatch";
import InfoHead from "../UI/infoHead/infoHead";
import Collapse from "../UI/collapse/Collapse";
import CardsMatch from "../blocks/match/CardsMatch";
import ForecastYears from "../blocks/Calculation/forecast/ForecastYears";
import ForecastPeriod from "../blocks/Calculation/forecast/ForecastPeriods";


const Math = () => {

    const CALCULATEMATCH_URL = '/calculation/match'
    const PERIODS_URL = '/calculation/match/periods'
    const YEARS_URL = '/calculation/match/years'
    const VARIFY_URL = '/role'

    const token = localStorage.getItem('token')
    const [dropdownOption, setDropDown] = useState(option)
    const [role, setRole] = useState('notAuth')

    const [username, setUsername] = useState(localStorage.getItem('matchName') || '')
    const [lastname, setLastname] = useState(localStorage.getItem('matchlastname') || '')
    const [familyname, setFamilyname] = useState(localStorage.getItem('matchfamilyname') || '')
    const [birthday, setBirthday] = useState(localStorage.getItem('matchbirthday') || '')
    const [month, setMounth] = useState(localStorage.getItem('matchmonth') || '')
    const [year, setYear] = useState(localStorage.getItem('matchyear') || '')
    const [person, setPerson] = useState([])
    const [mission, setMission] = useState('')
    const [mission_e, setMission_e] = useState('')
    const [gain, setGain] = useState([])
    const [gain_e, setGain_e] = useState([])
    const [years, setYears] = useState([])
    const [periods, setPeriods] = useState([])

    const [matchName, setMatchname] = useState(localStorage.getItem('match2Name') || '')
    const [matchLastname, setMatchLastname] = useState(localStorage.getItem('match2lastname') || '')
    const [matchFamilyname, setMatchFamilyname] = useState(localStorage.getItem('match2familyname') || '')
    const [matchBirthday, setMatchBirthday] = useState(localStorage.getItem('match2birthday') || '')
    const [matchMonth, setMatchMounth] = useState(localStorage.getItem('match2month') || '')
    const [matchYear, setMatchYear] = useState(localStorage.getItem('match2year') || '')
    const [matchPerson, setMatchPerson] = useState([])
    const [matchMission, setMatchMission] = useState('')
    const [matchMission_e, setMatchMission_e] = useState('')
    const [matchGain, setMatchGain] = useState([])
    const [matchGain_e, setMatchGain_e] = useState([])
    const [Matchperiods, setMatchPeriods] = useState([])
    const [matchYears, setMatchYears] = useState([])

    //совместимость
    const [card, setCards] = useState([])
    const [card_e, setCards_e] = useState([])
    const [baseMatch, setBaseMatch] = useState([])
    const [similarGain, setSimilarGain] = useState([])
    const [similarNumbers, setSimilarNumbers] = useState([])

    const [currentPeriodPeople1, setCurrentPeriodPeople1] = useState('')
    const [currentPeriodPeople2, setCurrentPeriodPeople2] = useState('')
    const [periodCard1, setPeriodCard1] = useState([])
    const [periodCard2, setPeriodCard2] = useState([])

    const [currentYearPeople1, setCurrentYearPeople1] = useState('')
    const [currentYearPeople2, setCurrentYearPeople2] = useState('')
    const [yearCard1, setYearCard1] = useState([])
    const [yearCard2, setYearCard2] = useState([])

    let [lang, setLang] = useState(localStorage.getItem('lang') || 'ru' )
    const [missionLang, setMissionLang] = useState(lang)
    const [loaded, setLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setError(false)
                setLoaded(true)
                if (response.data.role === 'student'){
                    setDropDown(StudentOption)
                } else (setDropDown(option))
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 422 ){
                    setRole('notAuth')
                    setDropDown(option)
                    setError(false)
                    setLoaded(true)
                }else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }};
            fetchData();
    }, [token]);

    const Calculate = async () =>{
        setIsLoading(true)
        if (username !=='' && lastname !== '' && familyname !== '' && birthday!== '' && month !== '' && year !== '' &&
            matchName !=='' && matchLastname !== '' && matchFamilyname !== '' && matchBirthday !== '' && matchMonth !== '' && matchYear !== ''){
            let head
            try {
                if (lang === ''){lang = localStorage.getItem('lang') || 'ru'}
                if (role === 'notAuth'){head = { 'Content-Type': 'application/json'}
                } else { head = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`}}
			const response = await axios.post(
				CALCULATEMATCH_URL,
				JSON.stringify({
                    'name': username, 'matchName' : matchName,
                    'familyname': familyname, 'matchFamilyname': matchFamilyname,
                    'lastname': lastname, 'matchLastname' : matchLastname,
                    'birthday': birthday, 'matchBirthday': matchBirthday,
                    'month': month, 'matchMonth': matchMonth,
                    'year': year, 'matchYear': matchYear,
                    'lang': lang
				}),{headers: head} 
			);

            localStorage.setItem('matchName', username)
            localStorage.setItem('matchfamilyname', familyname)
            localStorage.setItem('matchlastname', lastname)
            localStorage.setItem('matchbirthday', birthday)
            localStorage.setItem('matchmonth', month)
            localStorage.setItem('matchyear', year)

            localStorage.setItem('match2Name', matchName)
            localStorage.setItem('match2familyname', matchFamilyname)
            localStorage.setItem('match2lastname', matchLastname)
            localStorage.setItem('match2birthday', matchBirthday)
            localStorage.setItem('match2month', matchMonth)
            localStorage.setItem('match2year', matchYear)

                setPerson(response.data.people_1.main_circle)
                setMatchPerson(response.data.people_2.main_circle)

                if (missionLang === 'e'){
                    setGain(response.data.people_1.main_gain_e)
                    setMatchGain(response.data.people_2.main_gain_e)
                } else if (missionLang === 'ru_e'){
                    setGain(response.data.people_1.main_gain)
                    setMatchGain(response.data.people_2.main_gain)
                    setGain_e(response.data.people_1.main_gain_e)
                    setMatchGain_e(response.data.people_2.main_gain_e)
                } else {
                    setGain(response.data.people_1.main_gain)
                    setMatchGain(response.data.people_2.main_gain)
                }
                setYears(response.data.people_1.calculation_eventCircle_years)
                setPeriods(response.data.people_1.calculation_eventCircle_periods)

                setMatchYears(response.data.people_2.calculation_eventCircle_years)
                setMatchPeriods(response.data.people_2.calculation_eventCircle_periods)

                if (missionLang === 'e'){
                    for (let item = 0; item < response.data.people_2.main_circle.length; item++) {
                        if (response.data.people_2.main_circle[item].name === 'missiya_e'){
                            setMatchMission(response.data.people_2.main_circle[item].value);
                            break;
                        }
                    }
                    for (let item = 0; item < response.data.people_1.main_circle.length; item++) {
                        if (response.data.people_1.main_circle[item].name === 'missiya_e'){
                            setMission(response.data.people_1.main_circle[item].value);
                            break;
                        }
                    }
                } else if (missionLang === 'ru_e'){
                    for (let item = 0; item < response.data.people_1.main_circle.length; item++) {
                        if (response.data.people_1.main_circle[item].name === 'missiya'){
                            setMission(response.data.people_1.main_circle[item].value);
                        } if (response.data.people_1.main_circle[item].name === 'missiya_e'){
                            setMission_e(response.data.people_1.main_circle[item].value);
                        }
                    }
                    for (let item = 0; item < response.data.people_2.main_circle.length; item++) {
                        if (response.data.people_2.main_circle[item].name === 'missiya'){
                            setMatchMission(response.data.people_2.main_circle[item].value);
                        } if (response.data.people_2.main_circle[item].name === 'missiya_e'){
                            setMatchMission_e(response.data.people_2.main_circle[item].value);
                        }
                    }
                } else {
                    for (let item = 0; item < response.data.people_2.main_circle.length; item++) {
                        if (response.data.people_2.main_circle[item].name === 'missiya'){
                            setMatchMission(response.data.people_2.main_circle[item].value);
                            break;
                        }
                    }
                    for (let item = 0; item < response.data.people_1.main_circle.length; item++) {
                        if (response.data.people_1.main_circle[item].name === 'missiya'){
                            setMission(response.data.people_1.main_circle[item].value);
                            break;
                        }
                    }
                }

                setCurrentPeriodPeople1(response.data.people_1.eventCircle_currentPeriod)
                setCurrentPeriodPeople2(response.data.people_2.eventCircle_currentPeriod)
                setCurrentYearPeople1(response.data.people_1.eventCircle_currentYear)
                setCurrentYearPeople2(response.data.people_2.eventCircle_currentYear)
                
                setPeriodCard1(response.data.person_1_period_matches)
                setPeriodCard2(response.data.person_2_period_matches)
                setYearCard1(response.data.person_1_year_matches)
                setYearCard2(response.data.person_2_year_matches)

                setCards(response.data.personality_matches)
                setCards_e(response.data.personality_matches_e)
                setBaseMatch(response.data.external_matches)
                setSimilarGain(response.data.main_gain_matches)
                setSimilarNumbers(response.data.number_matches)

                setIsLoading(false)
		} catch (err) {
                alert(err.response.data.message)
                setIsLoading(false)
            } 
        } else {
            alert('Все поля должны быть заполнены')
            setIsLoading(false)
        }
    }

    function reset(){
        setUsername(''); setMatchname(''); setLastname('');
        setMatchLastname(''); setFamilyname(''); setMatchFamilyname('');
        setBirthday(''); setMatchBirthday(''); setMounth('');
        setMatchMounth(''); setYear(''); setMatchYear('');
        setPerson([])
        setMatchPerson([])
    }

    const handleOptionChange = (option) => {
        setLang(option)
        setMissionLang(option)
        setPerson([])
        setMatchPerson([])
    }

    const changePeriod = async (number, people) => {

        people === 'person1' ? setCurrentPeriodPeople1(number) : setCurrentPeriodPeople2(number)
        let jsonData
        let period
        let period2

        people === 'person1' ? period = number : period = currentPeriodPeople1
        people === 'person2' ? period2 = number : period2 = currentPeriodPeople2

        jsonData = {
        'people_1': {
            'main_circle': person,
            'familyname': familyname,
            'name': username,
            'lastname': lastname,
            'calculation_eventCircle_periods': {
                [period]:  people === 'person1' ? periods[number] : periods[currentPeriodPeople1]
            },
            'eventCircle_currentPeriod' : people === 'person1' ? number : currentPeriodPeople1
        },
        'people_2': {
            'main_circle': matchPerson,
            'familyname': matchFamilyname,
            'name': matchName,
            'lastname': matchLastname,
            'calculation_eventCircle_periods': {
                [period2]: people === 'person1' ? Matchperiods[currentPeriodPeople2] :  Matchperiods[number]
            },
            'eventCircle_currentPeriod': people === 'person2' ? number : currentPeriodPeople2
        }
        };
        try {
            const response = await axios.post(
                PERIODS_URL,
                jsonData, { headers: { 'Content-Type': 'application/json' }}
            );
            setPeriodCard1(response.data.person_1_period_matches)
            setPeriodCard2(response.data.person_2_period_matches)
        } catch (err) {
            alert(err.response.data.message)
        }
    }

    const changeYear = async (number, people) => {

        people === 'person1' ? setCurrentYearPeople1(number) : setCurrentYearPeople2(number)
        let jsonData
        let period
        let period2

        people === 'person1' ? period = number : period = currentYearPeople1
        people === 'person2' ? period2 = number : period2 = currentYearPeople2

        jsonData = {
        'people_1': {
            'main_circle': person,
            'familyname': familyname,
            'name': username,
            'lastname': lastname,
            'calculation_eventCircle_years': {
                [period]:  people === 'person1' ? years[number] : years[currentYearPeople1]
            },
            'eventCircle_currentYear' : people === 'person1' ? number : currentYearPeople1
        },
        'people_2': {
            'main_circle': matchPerson,
            'familyname': matchFamilyname,
            'name': matchName,
            'lastname': matchLastname,
            'calculation_eventCircle_years': {
                [period2]: people === 'person1' ? matchYears[currentYearPeople2] :  matchYears[number]
            },
            'eventCircle_currentYear': people === 'person2' ? number : currentYearPeople2
        }
        };
        try {
            const response = await axios.post(
                YEARS_URL,
                jsonData, { headers: { 'Content-Type': 'application/json' }}
            );
            setYearCard1(response.data.person_1_year_matches)
            setYearCard2(response.data.person_2_year_matches)
        } catch (err) {
            alert(err.response.data.message)
        }
    }

    function openNewPage(){
        window.open('/page/match', '_blank');
    }

    function print() {
        window.print()
    }

    return (
        <>
            <Header />
            {loaded &&
                <>
                    <div className={role === 'notAuth' ? 'contentSimple' : 'content'}>
                        <ScrollToTopButton />

                        <InfoHead content='СОВМЕСТИМОСТЬ' onClick={openNewPage} />
                        <button className="printBtn" onClick={print} >Печать</button>

                        {isLoading &&
                        <div className="spinner_box"><div className="spinner">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div></div>
                        }

                            <div className={classes.matchpage}>
                                <div className={classes.matchHeader}>
                                    <div className={classes.left}>
                                    <form className={classes.calcInput}>
                                        <Input 
                                            type='text'
                                            id='familyname'
                                            name='familyname'
                                            value={familyname}
                                            onChange={(e) => setFamilyname(e.target.value)}
                                            placeholder='Фамилия'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />
                                        <Input 
                                            type='text'
                                            id='username'
                                            name='username'
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            placeholder='Имя'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />
                                        <Input 
                                            type='text'
                                            id='lastname'
                                            name='lastname'
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                            placeholder='Отчество'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />

                                        <Input 
                                            type='number'
                                            id='birthday'
                                            name='birthday'
                                            value={birthday}
                                            onChange={(e) => setBirthday(e.target.value)}
                                            placeholder='день рождения'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />
                                        <Input 
                                            type='number'
                                            id='month'
                                            name='month'
                                            value={month}
                                            onChange={(e) => setMounth(e.target.value)}
                                            placeholder='месяц рождения'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />
                                        <Input 
                                            type='number'
                                            id='year'
                                            name='year'
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}
                                            placeholder='год рождения'
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter'){
                                                    Calculate()
                                                }
                                            }}
                                        />
                                    </form>
                                    </div>

                                    <div className={classes.middle}>
                                        <Button className={classes.calc} onClick={Calculate} disabled={isLoading}>
                                            <span>Посчитать</span>
                                        </Button>
                                        <Button className={classes.clean}  onClick={reset}>
                                            <span>Сбросить</span>
                                        </Button>
                                        <Dropdown option={dropdownOption} handleOptionChange={handleOptionChange}  />

                                    </div>

                                    <div className={classes.right}>
                                        <form className={classes.calcInput}>
                                            <Input 
                                                type='text'
                                                id='familyname'
                                                name='familyname'
                                                value={matchFamilyname}
                                                onChange={(e) => setMatchFamilyname(e.target.value)}
                                                placeholder='Фамилия'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='text'
                                                id='username'
                                                name='username'
                                                value={matchName}
                                                onChange={(e) => setMatchname(e.target.value)}
                                                placeholder='Имя'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='text'
                                                name='lastname'
                                                id='lastname'
                                                value={matchLastname}
                                                onChange={(e) => setMatchLastname(e.target.value)}
                                                placeholder='Отчество'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='birthday'
                                                name='birthday'
                                                value={matchBirthday}
                                                onChange={(e) => setMatchBirthday(e.target.value)}
                                                placeholder='день рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='month'
                                                name='month'
                                                value={matchMonth}
                                                onChange={(e) => setMatchMounth(e.target.value)}
                                                placeholder='месяц рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                            <Input 
                                                type='number'
                                                id='year'
                                                name='year'
                                                value={matchYear}
                                                onChange={(e) => setMatchYear(e.target.value)}
                                                placeholder='год рождения'
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter'){
                                                        Calculate()
                                                    }
                                                }}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className={classes.matchCircles}>
                                    <div className={classes.left}>
                                        {isLoading ? (
                                            <div className='krug'></div>
                                        ) : (
                                            <Krug data={person} role={role} mission={mission} misssion_e={mission_e} gain={gain} gain_e={gain_e} lang={lang} className='krug'  popUpLink='Circle' />
                                        )}
                                    </div>

                                    <div className={classes.middle}>
                                        {role  !== 'notAuth' && person.length > 0 &&
                                            <BaseMatch base={baseMatch} gain={similarGain} number={similarNumbers} />
                                        } 
                                    </div>

                                    <div className={classes.right}>
                                        {isLoading ? (
                                            <div className='krug'></div>
                                        ) : (
                                            <Krug data={matchPerson} role={role} mission={matchMission} misssion_e={matchMission_e} gain_e={matchGain_e} gain={matchGain} lang={lang} className='krug' popUpLink='Circle' />
                                        )}
                                    </div>
                                </div>

                            </div>
                        

                        <InfoHead content='СОВМЕСТИМОСТЬ ЛИЧНОСТНАЯ (в картах)' />
                            {role === 'notAuth' ?
                                <NavLink className={classes.navLink} to='/page/login'>
                                    Полная версия расчётов доступна после регистрации
                                </NavLink>
                                :
                                <Collapse className='collapse'>
                                        {card.length !== 0 &&
                                            <div className={classes.blockSmall}>
                                                <CardsMatch cards={card}   />
                                                {role === 'student' ?
                                                    <CardsMatch cards={card_e}/>
                                                    :
                                                    <NavLink className={classes.navLink} to='/page/Education'>
                                                        Полная версия расчётов доступна для студентов
                                                    </NavLink>
                                                }
                                            </div>
                                        }
                                </Collapse>
                            }

                        <InfoHead content='СОВМЕСТИМОСТЬ в ПЕРИОДАХ' />

                            {role === 'notAuth' ?
                                <NavLink className={classes.navLink} to='/page/login'>
                                    Полная версия расчётов доступна после регистрации
                                </NavLink>
                                :
                                <Collapse className='collapse'>
                                    <div className={periods.length === 0 ? classes.blockSimple : classes.block}>
                                        <ForecastPeriod periods={periods} page='match' role={role} current={currentPeriodPeople1} cards={periodCard1} changePeriod={changePeriod} person='person1' />
                                        <ForecastPeriod periods={Matchperiods} page='match' role={role} current={currentPeriodPeople2} cards={periodCard2} changePeriod={changePeriod} person='person2' />
                                    </div> 
                                </Collapse>
                            }

                        <InfoHead content='СОВМЕСТИМОСТЬ в ГОДАХ' />
                            {role === 'notAuth' ? 
                                <NavLink className={classes.navLink} to='/page/login'>
                                    Полная версия расчётов доступна после регистрации
                                </NavLink>
                                :
                                <Collapse className='collapse'>
                                    <div className={years.length === 0 ? classes.blockSimple : classes.block}>
                                        <ForecastYears years={years} changeYear={changeYear} current={currentYearPeople1} role={role} page='match' person='person1' cards={yearCard1}  />
                                        <ForecastYears years={matchYears} changeYear={changeYear} current={currentYearPeople2} role={role} page='match' person='person2' cards={yearCard2} />  
                                    </div> 
                                </Collapse>
                            }

                    </div>
                </>
            }

            {error &&
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />
        </>
    )
};

export default Math;
