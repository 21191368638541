import React, {useState, useEffect} from "react"
import { NavLink } from "react-router-dom";
import axios from '../../api/axios';

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import ScrollToTopButton from "../UI/button/ScrollToTopButton";

import Name from "../blocks/items/Name";
import InfoHead from "../UI/infoHead/infoHead";
import PlotCalc from "../blocks/Plot/PlotCalc";

const Plot = () => {

    const VARIFY_URL = '/role'

    const token = localStorage.getItem('token')
    const [role, setRole] = useState('notAuth')
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setError(false)
                setLoaded(true)
            } catch (error) {
                if(error.response?.status === 401 || error.response?.status === 422){
                    setRole('notAuth')
                    setError(false)
                    setLoaded(true)
                }else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }
        };
        fetchData();
    }, [token]);


    return (
        <>
            <Header />
            <div className={role === 'student' ? 'content' : 'contentSimple'}>
                <ScrollToTopButton />
                {loaded &&
                    <>
                    {role === 'notAuth' ?
                        <div className='noAccess'>
                            <p>Для получения доступа к расчёту сценариев необходимо приобрести курс или зарегистрироваться</p>
                            <p><i><NavLink to='/page/login' className='link' > зарегистрироваться / войти в аккаунт</NavLink></i></p>
                            <p><i><NavLink to='/page/Education' className='link' > посмотреть список доступных курсов</NavLink></i></p>
                        </div>

                        : 
                        <>
                        <InfoHead content='СЦЕНАРИИ'/>
                        <div className="page">
                            <PlotCalc role={role} />
                            <InfoHead content='ПОДБОР ИМЕНИ' />
                            <Name />
                        </div>
                        </>
                    }
                    </>
                }
                {error &&
                    <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                    Ошибка соединения с сервером</p>
                }
            </div>
            <Footer />
        </>
    )
};

export default Plot;
