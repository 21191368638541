import React from 'react';
import './PopUp.css'

const InfoPopUp = ({active, setActive, children, popUpTitle}) => {
    return (
        <div className={ active ? 'modal active' : 'modal'} onClick={ () => setActive(false)} >
            
            <div className={ active ? 'modal__content active' : 'modal__content'} onClick={ e => e.stopPropagation()} >
                <h3>{popUpTitle}</h3>
                <div className='modal__frame'>
                    {children}
                </div>
                
            </div>
        </div>
    );
}

export default InfoPopUp;
