import React from 'react'
import classes from './items.module.css'

const BookItem = ({book}) => {

    const formattedText = book.description.replace(/\n/g, '<br>');

    return (
        <div className={classes.container}>
            <h3 className={classes.title}>{book.title}</h3>
            <img src={`/img/png/book/${book.imagePaths}`} alt={book.title} className={classes.bookImg} />

            <div className={classes.starText} dangerouslySetInnerHTML={{ __html: formattedText }} />
        </div>
    );
}

export default BookItem;
