import React from 'react';

import CustomLink from '../../UI/customLink/customLink';
import InfoHead from '../../UI/infoHead/infoHead';

const Header = ({content}) => {

    const headerLinks = [
      {id: '/author', title: '  О СИСТЕМЕ', link: '/page/author'},
      {id: '/decode', title: 'ДЕКОДЕР', link: '/'},
      {id: '/match', title: 'СОВМЕСТИМОСТЬ', link: '/page/match'},
      {id: '/plot', title: 'СЦЕНАРИИ', link: '/page/plot'},
      {id: '/forecast', title: 'КАРТА ДНЯ', link: '/page/forecast'},
      {id: '/archive', title: 'КАРТОТЕКА', link: '/page/archive'},
      {id: '/profile', title: ' ПРОФИЛЬ', link: '/page/profile'},
    ]

    return (
      <nav>
        <div className='header'>
            {headerLinks.map((link) =>
              <CustomLink link={link}   key={link.id}></CustomLink> 
            )}
          </div>
          <InfoHead content={content} />
        </nav>
    );
}

export default Header;
