import React from "react"
import classes from '../Calc.module.css'

import YearItem from "./YearItem";
import Krug from "../Krug";
import MatchCard from "../../match/MatchCard";

const ForecastYears = ({years, changeYear, current, cards, page, role, person }) => {

    let yearDuration

    if (years[current] !== undefined){
        for (let item=0; item < years[current].length; item++){
            if (years[current][item].name === 'circle_year'){
                yearDuration = (years[current][item].value);
            } 
        }
    }
    return (
        <div className={classes.forecast__container}>
            <h2>ГОДА</h2>
            {page === 'match' && years[current] !== undefined &&
                <div>
                    <h2>Действующий ГОД c {yearDuration - 1} по {yearDuration}</h2> 
                    <div className={classes.period__forecast}>
                        <Krug data={years[current]} className='krug__year' role={role} popUpLink='Year' />   

                        <div>
                            {cards.length !== 0 ?
                                cards.map((item,index) => (
                                    <MatchCard key={index} card={item} />
                                ))
                                : <p><i>Совместимостей нет</i></p>
                            } 
                        </div>
                    </div>
                </div>
            }
            {years.length !== 0 &&
                <div className={classes.periods}>
                    <YearItem item={years[1]} number={1} changeYear={changeYear} person={person}/>
                    <YearItem item={years[2]} number={2} changeYear={changeYear} person={person}/>
                    <YearItem item={years[3]} number={3} changeYear={changeYear} person={person}/>
                    <YearItem item={years[4]} number={4} changeYear={changeYear} person={person}/>
                    <YearItem item={years[5]} number={5} changeYear={changeYear} person={person}/>
                    <YearItem item={years[6]} number={6} changeYear={changeYear} person={person}/>
                    <YearItem item={years[7]} number={7} changeYear={changeYear} person={person}/>
                    <YearItem item={years[8]} number={8} changeYear={changeYear} person={person}/>
                    <YearItem item={years[9]} number={9} changeYear={changeYear} person={person}/>
                </div>
            }
        </div>
    )
};

export default ForecastYears;
