import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from '../../api/axios';
import classes from '../blocks/rightPage/Video.module.css';

import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
// import Input from '../UI/input/Input';
import VideoBlock from '../blocks/rightPage/VideoBlock';
import InfoHead from '../UI/infoHead/infoHead';

const StudyVideo = () => {

    const STUDY_URL = '/Study/category'
    const VARIFY_URL = '/role'

    const token = localStorage.getItem('token')
    const [role, setRole] = useState('notAuth')
    const [header, setHeader] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)

    const [svCat, setSVCat] = useState([])
    const [expandedCategories, setExpandedCategories] = useState({});

    const [subcategories, setSubcategories] = useState([]);
    const [nested, setNested] = useState([])

    const [studyVideo, setStudyVideo] = useState([])
    const [emptyVideo, setEmptyVideo] = useState(false)

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setLoaded(true)
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 422){
                    setRole('notAuth')
                    setLoaded(true)
                } else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }};
            fetchData();
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(STUDY_URL, {
                    headers: {Authorization: `Bearer ${token}`},
                });
                setSVCat(response.data);

                const subcategoriesMap = {};
                for (const category of response.data) {
                    const subcategoriesResponse = await axios.get(`/Study/${category.id}/subcategory`);
                    subcategoriesMap[category.title] = subcategoriesResponse.data.sort((a, b) => a.title.localeCompare(b.title));
                }
                setSubcategories(subcategoriesMap);
            } catch (error) {
            }
        };
        fetchData();
    }, [token]);

    const toggleCategory = (categoryTitle) => {
        setExpandedCategories((prevExpandedCategories) => ({
            ...prevExpandedCategories,
            [categoryTitle]: !prevExpandedCategories[categoryTitle],
        }));
    };

    function openDetails(e, item) {
        e.preventDefault()
        setHeader(e.target.innerText)
        if (e.target.innerText === header && nested.length !== 0 ){
            setNested([])
        } else {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`/Study/${item}/nested`);
                    setNested(response.data);
                } catch (error) {
                }
            };
            fetchData();
        }

    }

    function openVideo(item) {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/Study/${item}/video`, { 
                    headers: {Authorization: `Bearer ${token}`},
                })
                setStudyVideo(response.data);
                setEmptyVideo(false)
                if (response.data.length === 0){
                    setEmptyVideo(true)
                    setStudyVideo([])
                }
            } catch (error) {
            }
        }
        fetchData()
    }

    useEffect(() => {
        const filteredCategories = svCat.filter(category =>
            category.title.toLowerCase().includes(searchInput.toLowerCase()) ||
            (subcategories[category.title] && subcategories[category.title].some(subcategory =>
                subcategory.title.toLowerCase().includes(searchInput.toLowerCase())
            ))
        );
        const updatedExpandedCategories = {};
        setFilteredCategories(filteredCategories);
        setExpandedCategories(updatedExpandedCategories);
    }, [searchInput, svCat, subcategories]);

    return (
        <>
            <Header />
            {loaded &&
                <div className="contentSimple">
                    {role !== 'student' ? 
                        <div className='noAccess'>
                            <p>Для получения доступа необходимо приобрести курс</p>
                            <i><NavLink to='/page/Education' className='link'  > посмотреть список доступных курсов</NavLink></i>
                        </div> :
                        <>
                            <InfoHead content='ВИДЕО-КУРСЫ' />
                            <div className="book">
                                <div className="leftPage">
                                    {svCat.length === 0 ? 
                                        <p><i>Здесь пока ничего нет</i></p> :
                                        <div className="colums">
                                            {/* <Input 
                                                type='search'
                                                placeholder='Поиск...'
                                                className='search'
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            /> */}
                                            {!filteredCategories.length ? (<p className={classes.CategoryItem}><i>Ничего не найдено</i></p>) 
                                            : (filteredCategories.map((category, index) => (
                                                <div key={index}>
                                                    <div className={classes.CategoryItem} onClick={() => toggleCategory(category.title)}>
                                                        <h3>{category.title} </h3>
                                                        {expandedCategories[category.title] ? <span>свернуть</span> : <span>посмотреть</span> }
                                                    </div>
                                                    <hr />

                                                    {expandedCategories[category.title] && subcategories[category.title] && (
                                                        <ul>
                                                            {subcategories[category.title].map((subcategory, index) => (
                                                                <div  key={index} style={{display: 'flex', flexDirection: 'column'}}>
                                                                    <div style={{display: 'flex', gap: '5px'}}>
                                                                        <h3 onClick={(e) => openDetails(e, subcategory.id)} style={{cursor: 'pointer', marginLeft: '15px'}}>{subcategory.title}</h3>
                                                                    </div>
                                                                    {subcategory.link === null ? <p style={{marginLeft:'45px'}}></p> : 
                                                                        <iframe
                                                                            title={`${subcategory.title}`}
                                                                            style={{float: 'left', margin: '10px'}} 
                                                                            width="300" 
                                                                            height="200" 
                                                                            src={`https://www.youtube.com/embed/${subcategory.link}`} 
                                                                            frameBorder="0" 
                                                                            allowFullScreen
                                                                        ></iframe>  
                                                                    }

                                                                    <div className={classes.nestedBlock}>
                                                                        {nested.length !== 0 ?
                                                                            subcategory.title === header ?
                                                                                nested.map((item, index) => {
                                                                                    return <li 
                                                                                                key={index}
                                                                                                onClick={() => openVideo(item.id)}
                                                                                                className={classes.nested}
                                                                                            >
                                                                                            {item.title}</li>
                                                                                        }) :  <li></li>
                                                                                : <p></p>
                                                                        } 
                                                                    </div>
                                                                    
                                                                </div>
                                                            ))}

                                                            {subcategories[category.title].length === 0 && <span><i>Подкатегорий пока нет</i></span> }
                                                        </ul>
                                                    )}
                                                </div>
                                            )))
                                            }

                                        </div>
                                    }
                                </div>
                                <div className="rightPage">
                                    {studyVideo.length !== 0 ?
                                        studyVideo.map((video, index) =>
                                            <VideoBlock video={video} key={index} />
                                        )
                                        : <p></p>
                                    }
                                    {emptyVideo && <p>Здесь пока ничего нет</p> }
                                </div>
                            </div>
                        </>
                    }
                </div>
            }

            {error &&
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />
            
        </>
    )
};

export default StudyVideo;
