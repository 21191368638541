// не авторизованные не видят
export const excludeList = ["vibor_osnova_e", "vibor_osnova", "vibor_ambicii_e", "vibor_ambicii" , "vibor_garmoniya_e", "vibor_garmoniya", "slabost_e", "slabost", "sila_e", "sila"]
    
// для неавторизованных
export const notAuth = ['komfort', 'nepodarok', 'zapas', 'kozir', 'oshibka', 'predatel', 'fars', 'antiyadro', 'yadro', 'antigrezi', 'grezi', 
                        'nevihod_n', 'komfort_n', 'nepodarok_n', 'zapas_n', 'nevrag_n', 'kozir_n', 'oshibka_n', 'predatel_n', 'uchitel', 'muchitel', 'nuzhda' ]

// основной круг
export const mainKrug = ['fiasko', 'missiya', 'antioblik', 'oblik', 'telo_talant', 'antitelo','smisl', 'sut', 'rutina', 'argument', 'stremlenie', 'argument_n', 'stremlenie_n',
                        'vibor_poterya', 'vibor_energiya', 'vibor_zadacha', 'vibor_palach', 'nevihod', 'nevrag', 'bremya', 'resurs']
//весь круг
export const fullKrug = ['fiasko', 'missiya', 'antioblik', 'oblik', 'telo_talant', 'antitelo','smisl', 'sut', 'rutina', 'argument', 'stremlenie', 'argument_n', 'stremlenie_n',
'vibor_poterya', 'vibor_energiya', 'vibor_zadacha', 'vibor_palach', 'nevihod', 'nevrag', 'bremya', 'resurs','komfort', 'nepodarok', 'zapas', 'kozir', 'oshibka', 'predatel', 'fars', 'antiyadro', 'yadro', 'antigrezi', 'grezi', 
'nevihod_n', 'komfort_n', 'nepodarok_n', 'zapas_n', 'nevrag_n', 'kozir_n', 'oshibka_n', 'predatel_n', 'uchitel', 'muchitel', 'nuzhda' ]

//круг с ё
export const fullKrug_e = ['fiasko_e', 'missiya_e', 'antioblik_e', 'oblik_e', 'telo_talant_e', 'antitelo_e','smisl_e', 'sut_e', 'rutina_e', 'argument_e', 'stremlenie_e', 'argument_n_e', 'stremlenie_n_e',
'vibor_poterya_e', 'vibor_energiya_e', 'vibor_zadacha_e', 'vibor_palach_e', 'nevihod_e', 'nevrag_e', 'bremya_e', 'resurs_e','komfort_e', 'nepodarok_e', 'zapas_e', 'kozir_e', 'oshibka_e', 'predatel_e', 'fars_e', 
'antiyadro_e', 'yadro_e', 'antigrezi_e', 'grezi_e','nevihod_n_e', 'komfort_n_e', 'nepodarok_n_e', 'zapas_n_e', 'nevrag_n_e', 'kozir_n_e', 'oshibka_n_e', 'predatel_n_e', 'uchitel_e', 'muchitel_e', 'nuzhda_e' ]

export const krug_pro = ['fiasko_e', 'missiya_e', 'telo_talant_e', 'antitelo_e','telo_talant_e', 'antitelo_e','sut_e', 'vibor_poterya_e', 'vibor_energiya_e', 'vibor_zadacha_e', 'vibor_palach_e', 'argument_e', 'stremlenie_e',]

// таблица архива
export  const tableHeaders = [
    { id: 1, name: 'Фамилия', backgroundColor: '#efde72', title: 'familyname' },
    { id: 2, name: 'Имя', backgroundColor: '#efde72',       title: 'username' },
    { id: 3, name: 'Отчество', backgroundColor: '#efde72', title: 'lastname' },
    { id: 4, name: 'Дата', backgroundColor: '#efde72', title: 'birthday' },
    { id: 7, name: 'Миссия / Фиаско I', backgroundColor: '#b2d384', title: 'missiya' },
    { id: 8, name: 'Миссия / Фиаско II', backgroundColor: '#95cdd9', title: 'missiya_e' },
    { id: 9, name: 'Выбор (Задача)', backgroundColor: '#b2d384', title: 'vibor_zadacha' },
    { id: 10, name: 'Выбор (Основа)', backgroundColor: '#b2d384', title: 'vibor_osnova' },
    { id: 11, name: 'Выбор (Палач)', backgroundColor: '#db5556', title: 'vibor_palach' },
    { id:12, name: 'Выбор (Суть)', backgroundColor: '#db5556', title: 'sut' },
    { id: 13, name: 'Облик (Успех /Провал)', backgroundColor: '#a685f8', title: 'oblik' },
    { id: 14, name: 'Тело (Талант /Крах)', backgroundColor: '#fc9f4f', title: 'telo_talant' },
    { id: 15, name: 'Сила /Слабость', backgroundColor: '#fc9f4f',title: 'sila' },
    { id: 16, name: 'Бремя /Ресурс', backgroundColor: '#95cdd9', title: 'bremya' },
    { id: 17, name: 'Выбор (Энергия)', backgroundColor: '#b2d384', title: 'vibor_energiya' },
    { id: 19, name: 'Выбор (Гармония)', backgroundColor: '#b2d384', title: 'vibor_garmoniya' },

    { id: 18, name: 'Выбор (Потеря)', backgroundColor: '#db5556', title: 'vibor_poterya' },
    { id: 20, name: 'Выбор (Амбиции)', backgroundColor: '#db5556', title: 'vibor_ambicii' },
];

//архив
export  const list = ['missiya', 'missiya_e', 'fiasko', 'fiasko_e', 'sut', 'vibor_zadacha', 'vibor_osnova', 'vibor_palach', 'oblik', 'telo_talant', 'sila', 'bremya', 'vibor_energiya', 'vibor_poterya', 'vibor_garmoniya', 'vibor_ambicii']

// выпадающий для всех
export const option = [
    {title: 'Русский', value: 'ru', name: 'ru'},
    {title: 'Азербайджанский', value: 'az', name: 'az'},
    {title: 'Английский', value: 'en', name: 'en'},
    {title: 'Белорусский', value: 'by', name: 'by'},
    {title: 'Болгарский', value: 'bg', name: 'bg'},
    {title: 'Греческий', value: 'gr', name: 'gr'},
    {title: 'Испанский', value: 'es', name: 'es'},
    {title: 'Итальянский', value: 'it', name: 'it'},
    {title: 'Латышский', value: 'lv', name: 'lv'},
    {title: 'Литовский', value: 'lt', name: 'lt'},
    {title: 'Немецкий', value: 'de', name: 'de'},
    {title: 'Нидерландский', value: 'ned', name: 'ned'},
    {title: 'Польский', value: 'pl', name: 'pl'},
    {title: 'Португальский', value: 'pt', name: 'pt'},
    {title: 'Румынский', value: 'ro', name: 'ro'},
    {title: 'Сербский', value: 'serbian', name: 'serbian'},
    {title: 'Словенский', value: 'si', name: 'si'},
    {title: 'Турецкий', value: 'tr', name: 'tr'},
    {title: 'Украинский', value: 'ua', name: 'ua'},
    {title: 'Финский', value: 'fi', name: 'fi'},
    {title: 'Французский', value: 'fr', name: 'fr'},
    {title: 'Черногорский', value: 'me', name: 'me'},
    {title: 'Чешский', value: 'cz', name: 'cz'},
    {title: 'Шведский', value: 'swed', name: 'swed'},
    {title: 'Эстонский', value: 'ee', name: 'ee'},
]

// выпадающий для студентов
export const StudentOption = [
    {title: 'Русский', value: 'ru', name: 'ru'},
    {title: 'Русский PRO', value: 'ru_e', name: 'ru_e'},
    {title: 'Азербайджанский', value: 'az', name: 'az'},
    {title: 'Английский', value: 'en', name: 'en'},
    {title: 'Белорусский', value: 'by', name: 'by'},
    {title: 'Болгарский', value: 'bg', name: 'bg'},
    {title: 'Греческий', value: 'gr', name: 'gr'},
    {title: 'Испанский', value: 'es', name: 'es'},
    {title: 'Итальянский', value: 'it', name: 'it'},
    {title: 'Латышский', value: 'lv', name: 'lv'},
    {title: 'Литовский', value: 'lt', name: 'lt'},
    {title: 'Немецкий', value: 'de', name: 'de'},
    {title: 'Нидерландский', value: 'ned', name: 'ned'},
    {title: 'Польский', value: 'pl', name: 'pl'},
    {title: 'Португальский', value: 'pt', name: 'pt'},
    {title: 'Румынский', value: 'ro', name: 'ro'},
    {title: 'Сербский', value: 'serbian', name: 'serbian'},
    {title: 'Словенский', value: 'si', name: 'si'},
    {title: 'Турецкий', value: 'tr', name: 'tr'},
    {title: 'Украинский', value: 'ua', name: 'ua'},
    {title: 'Финский', value: 'fi', name: 'fi'},
    {title: 'Французский', value: 'fr', name: 'fr'},
    {title: 'Черногорский', value: 'me', name: 'me'},
    {title: 'Чешский', value: 'cz', name: 'cz'},
    {title: 'Шведский', value: 'swed', name: 'swed'},
    {title: 'Эстонский', value: 'ee', name: 'ee'},
    {title: 'Альтернативный', value: 'e', name: 'e'},
]

//график
export const grafic = {
    "1": {"description": "Альфа Мужчина/Бета Женщина", "grafic": "/img/png/grafic/gr1.png"},
    '2': {'description': 'Альфа Женщина/Бета Мужчина', 'grafic': '/img/png/grafic/gr2.png'},
    '3': {'description': 'Альфа Мужчина/Бета Женщина', 'grafic': '/img/png/grafic/gr3.png'},
    '4': {'description': 'Альфа Женщина/Бета Мужчина', 'grafic': '/img/png/grafic/gr4.png'},
    '5': {'description': 'Альфа Женщина/Бета Мужчина', 'grafic': '/img/png/grafic/gr5.png'},
    '6': {'description': 'Альфа Женщина/Бета Мужчина', 'grafic': '/img/png/grafic/gr6.png'},
    '7': {'description': 'Альфа Мужчина/Бета Женщина', 'grafic': '/img/png/grafic/gr7.png'},
    '8': {'description': 'Альфа Женщина/Бета Мужчина',  'grafic': '/img/png/grafic/gr8.png'},
    '9': {'description': 'Альфа Мужчина/Бета Женщина', 'grafic': '/img/png/grafic/gr9.png'}
}

//мини круг в периодах
export const mini_circle_list__periods = ["period", "antiperiod", "vozmojnost_p", "zadacha_p", "palach_p", 
                    "argument_p", "stremlenie_p", "ispitanie_p", "neispitanie_p", 
                    "antinavik_p", "navik_p", "nevozmojnost_p"]

// мини круг в годах
export const mini_circle_list__years = ["god", "antigod", "vozmojnost_y", "zadacha_y", "palach_y", 
                    "argument_y", "stremlenie_y", "ispitanie_y", "neispitanie_y", 
                    "antinavik_y", "navik_y", "nevozmojnost_y"]


// прогноз событий и эмоций для периода
export const eventCircle__period = ["period", "antiperiod", "vozmojnost_p", "zadacha_p", "palach_p", "argument_p", "stremlenie_p", "ispitanie_p", "neispitanie_p", "antinavik_p",
                                    "navik_p", "navajdenie_p", "ispitanie_radost_p", "neispitanie_slezi_p",
                                    "navik_slabost_p", "navik_sila_p", "proshloe_nauka_p", "proshloe_opit_p", "vibor_poterya_p", "vibor_energiya_p", "nevihod_p", 
                                    "komfort_p", "nepodarok_p", "zapas_p", "nevrag_p", "kozir_p", "oshibka_p", "predatel_p", "antiyadro_p", "yadro_p", "antigrezi_p", "grezi_p",
                                    "argument_n_p", "stremlenie_n_p", "nevihod_n_p", "komfort_n_p", "nepodarok_n_p", "zapas_n_p", "nevrag_n_p", "kozir_n_p", "oshibka_n_p",
                                    "predatel_n_p", 'nevozmojnost_p']

// прогноз событий и эмоций для года
export const eventCircle__year = ["god", "antigod", "vozmojnost_y", "zadacha_y", "palach_y", "argument_y", "stremlenie_y", "ispitanie_y", "neispitanie_y", "antinavik_y",
                                    "navik_y", "navajdenie_y", "ispitanie_radost_y", "neispitanie_slezi_y",
                                    "navik_slabost_y", "navik_sila_y", "proshloe_nauka_y", "proshloe_opit_y", "vibor_yoterya_y", "vibor_energiya_y", "nevihod_y", 
                                    "komfort_y", "nepodarok_y", "zapas_y", "nevrag_y", "kozir_y", "oshibka_y", "predatel_y", "antiyadro_y", "yadro_y", "antigrezi_y", "grezi_y",
                                    "argument_n_y", "stremlenie_n_y", "nevihod_n_y", "komfort_n_y", "nepodarok_n_y", "zapas_n_y", "nevrag_n_y", "kozir_n_y", "oshibka_n_y",
                                    "predatel_n_y", 'nevozmojnost_y', 'vibor_poterya_y']

export const emtyBirthday = [ 'telo_talant_e', 'antitelo_e','smisl_e', 'argument_n_e', 'stremlenie_n_e', 'vibor_poterya_e', 'vibor_energiya_e','bremya_e', 'resurs_e', 
                                    'nevihod_n_e', 'komfort_n_e', 'nepodarok_n_e', 'zapas_n_e', 'nevrag_n_e', 'kozir_n_e', 'oshibka_n_e', 'predatel_n_e', 
                                    'muchitel_e', 'nuzhda_e' ]