import React, { useState, useEffect } from "react"
import axios from '../../api/axios';

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import ExtraItem from "../blocks/items/ExtraItem";
import MainItem from "../blocks/items/MainItem";
import InfoHead from "../UI/infoHead/infoHead";

const Consultation = () => {

    const CONSULTATION_URL = '/consultation/get?level=main'
    const EXTRA_CONSULTATION_URL = '/consultation/get?level=extra'
    const [consultation, setConsultation] = useState([])
    const [extraConsultation, setExtaConsultation] = useState([])

    const [loaded, setLoaded] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(CONSULTATION_URL);
                setConsultation(response.data);
                const extra__response = await axios.get(EXTRA_CONSULTATION_URL);
                setExtaConsultation(extra__response.data);
                setLoaded(true)
                setError(false)
            } catch (error) {
                if (error.message === 'Network Error'){
                    setError(true)
                    setLoaded(false)
                }
            }
        };
        fetchData();
    }, []);

    return (
            <>
                <Header  />
                {loaded &&
                    <div className="contentSimple">
                        <InfoHead content='КОНСУЛЬТАЦИИ'/>
                        <div className="book">
                            <div className="leftPage">
                                <h2>Основные</h2>
                                {consultation.length === 0 ?
                                    <p><i>Пока нет доступных консультаций</i></p> 
                                    : consultation.map( (item, index) => 
                                        <MainItem data={item} key={index} />
                                    )
                                }
                            </div>
                            <div className="rightPage">
                                <h2>Дополнительные</h2>
                                {extraConsultation.length === 0 ? 
                                    <p><i>Пока нет доступных консультаций</i></p> 
                                    : extraConsultation.map((item, index) => 
                                    <ExtraItem data={item} key={index} />
                                )}
                            </div>
                        </div>
                    </div>
                    

                }

                {error &&
                    <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                    Ошибка соединения с сервером</p>
                }

                <Footer />
            </>
    )
};

export default Consultation;
