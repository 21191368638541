import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from '../../api/axios';
import getDate from "../../api/getDate";
import classes from './styles/almanac.module.css'

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import Input from "../UI/input/Input";
import AlmonacItem from "../blocks/rightPage/AlmonacItem";
import InfoHead from "../UI/infoHead/infoHead";

const Almanac = () => {

    const ALMANAC_URL = '/almanah'
    const VARIFY_URL = '/role'
    const [emptyAlmanac, setEmptyAlmanac] = useState(false)
    const [forbidden, setForbidden] = useState(false)

    const [role, setRole] = useState('notAuth')
    const token = localStorage.getItem('token')
    const [loaded, setLoaded] = useState(true)
    const [error, setError] = useState(false)

    const [list, setList] = useState([])
    const [pageContent, setPageContent] = useState([])
    const [searchInput, setSearchInput] = useState("")

    const [expandedCategories, setExpandedCategories] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(VARIFY_URL, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setRole(response.data.role)
                setLoaded(true)
            } catch (error) {
                if (error.response?.status === 401 || error.response?.status === 422){
                    setLoaded(true)
                    setRole('notAuth')
                } else if (error.message === 'Network Error'){
                    setLoaded(false)
                    setError(true)
                }
            }};
            fetchData();
    }, [token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(ALMANAC_URL);
                setList(response.data.reverse())
                const categories = response.data.map(item => item.page);
                const expandedCategoriesInit = categories.reduce((acc, category) => {
                    acc[category] = true;
                    return acc;
                }, {});
                setExpandedCategories(expandedCategoriesInit);
            } catch (error) {
            }};fetchData();
        }, []);


    function openDetails(page, item) {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/${page}/${item}/video`);
                setPageContent(response.data);
                setEmptyAlmanac(false)
                setForbidden(false)
            } catch (error) {
                if (error.response?.status === 404 ){
                    setEmptyAlmanac(true)
                    setForbidden(false)
                    setPageContent([])
                }
            }
        };fetchData();
    }

    function openVideo(item) {
        const fetchData = async () => {

            try {
                const response = await axios.get(`/Study/${item}/video`, { 
                    headers: {Authorization: `Bearer ${token}`},
                });
                setPageContent(response.data);
                setEmptyAlmanac(false)
                setForbidden(false)
            } catch (error) {
                if (error.response?.status === 404 ){
                    setEmptyAlmanac(true)
                    setForbidden(false)
                    setPageContent([])
                } else if (error.response?.status === 403 ){
                    setEmptyAlmanac(false)
                    setForbidden(true)
                    setPageContent([])
                }
            }
        };fetchData();
    }

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };
    
    const filterBySearchInput = (text) => {
        const regex = new RegExp(searchInput, 'gi');
        return text.replace(regex, (match) => `<mark>${match}</mark>`);
    };

    const filteredList = list.filter(
        (item) =>
            item.title.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.date.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <>
            <Header/>
            {loaded &&
                <div className="contentSimple">
                    <InfoHead content='АЛЬМАНАХ' />
                    <div className="book almanac">
                        <div className="leftPage">
                        {list.length === 0 ?
                            <p><i>Здесь пока ничего нет</i></p> :
                                <>
                                    <Input 
                                        type='search'
                                        placeholder='Поиск...'
                                        className='search'
                                        value={searchInput}
                                        onChange={handleSearchChange}
                                    />
                                    <h3>СОДЕРЖАНИЕ:</h3>
                                    <div style={{alignItems: 'baseline'}}>
                                        {filteredList.map((item, index) => {
                                            if (item.page === 'Study' ){
                                                if (role === 'student'){
                                                    return <p className={classes.list} onClick={() => openVideo(item.keyword)} key={index}>
                                                        {getDate(item.date)} - <span dangerouslySetInnerHTML={{__html: filterBySearchInput(item.title)}}></span>
                                                    </p>
                                                } else {
                                                    return
                                                }
                                            } else if (item.page === 'Education' || item.page === 'Consultation'){
                                                return <Link key={index} to={`/page/${item.page}`} title="Перейти" target="_blank" className={classes.list}>
                                                            <p>{getDate(item.date)} - {item.title}</p>
                                                        </Link>
                                            } else {
                                                return <p className={classes.list} onClick={() => openDetails(item.page, item.keyword)} key={index}>
                                                    {getDate(item.date)} - <span dangerouslySetInnerHTML={{__html: filterBySearchInput(item.title)}}></span>
                                                </p>
                                            }
                                        })}
                                    </div>
                                    {filteredList.length === 0 && 
                                        <p style={{alignItems: 'center'}}><i>Новых публикаций нет</i></p>
                                    }
                                </>
                        }
                        </div>

                        <div className="rightPage">
                            {pageContent.length !== 0 && <AlmonacItem data={pageContent} />}
                            {emptyAlmanac && <p>Данных пока нет</p> }
                            {forbidden && <p>Для доступа необходимо приобрести курс</p> }
                        </div>
                    </div>
                </div>
            }

            {error &&
                <p style={{display: 'flex', justifyContent: 'center', marginTop: '10px', fontSize: '22px', color: 'white'}}>
                Ошибка соединения с сервером</p>
            }
            <Footer />
        </>
    )
};

export default Almanac;

