import React from "react"
import classes from '../Calc.module.css'

import PeriodItem from "./PeriodItem";
import Krug from "../Krug";
import MatchCard from "../../match/MatchCard";

const ForecastPeriod = ({ periods, changePeriod, current, person,  page, role, cards}) => {

    let eventDuration = []

    if (periods[current] !== undefined){
        for (let item=0; item < periods[current].length; item++){
            if (periods[current][item].name === 'duration'){
                eventDuration = (periods[current][item].value.split('-'));
            } 
        }
    }

    return (
        <div className={classes.forecast__container}>
            <h2>ПЕРИОДЫ</h2>
            {page === 'match' && periods[current] !== undefined && 
                <div>
                    <h2>Действующий ПЕРИОД c {eventDuration[0]} по {eventDuration[1]}</h2>
                    <div className={classes.period__forecast}>
                        <Krug data={periods[current]} className='krug__period' role={role} popUpLink='Period' />   
                        <div>
                            {cards.length !== 0 ?
                                cards.map((item,index) => (
                                    <MatchCard key={index} card={item} />
                                ))
                                : <p><i>Совместимостей нет</i></p>
                            }
                        </div>
                    </div>
                </div>
            }

            {periods.length !== 0 &&
                <div className={classes.periods}>
                    <PeriodItem item={periods[1]} number={1} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[2]} number={2} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[3]} number={3} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[4]} number={4} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[5]} number={5} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[6]} number={6} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[7]} number={7} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[8]} number={8} changePeriod={changePeriod} person={person}/>
                    <PeriodItem item={periods[9]} number={9} changePeriod={changePeriod} person={person}/>
                </div>
            }
        </div>
    )
};

export default ForecastPeriod;
