import React from "react"

import VideoBlock from "./VideoBlock";
import StarsItem from "./StarsItem";
import BookItem from "./BookItem";

const AlmonacItem = ({data}) => {
    return (
        <div>
            {data.birthday  &&
                <StarsItem star={data} />
            }

            {data.length > 0 &&
                data.map( (video, index) =>
                    <VideoBlock video={video} key={index} />
                )
            }

            {data.imagePaths &&
                <BookItem book={data} />
            }
        </div>
    )
};

export default AlmonacItem;
